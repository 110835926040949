import React, { useState } from "react"
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Box } from "@mui/material";
import { Typography, useTheme, useMediaQuery } from "@mui/material";
import HeaderSectionItem from "../dektopHeader/headerSectionItem";
import { navigate } from "gatsby";

import { styled } from "@mui/system";


const StyledAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    justifyContent: "center",
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
        paddingLeft: "calc(50% - 250px)",
        paddingRight: "calc(50% - 250px)"
    },
    [theme.breakpoints.down('sm')]: {
        paddingLeft: "calc(50% - 120px)",
        paddingRight: "calc(50% - 120px)"
    },
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
}));

const Accordion = styled(MuiAccordion)(({ theme }) => ({
    boxShadow: 'none',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    '&$expanded': {
        margin: 'auto',
    },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
        minHeight: 56,
    },

    '& .MuiAccordionSummary-content': {
    justifyContent: "center",
    cursor: "pointer",
    borderBottom: "2px solid rgba(0,0,0,0)",
    transition: "border-bottom .3s !important",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    '&$expanded': {
        margin: '12px 0',
        textDecoration: "underline",
        textDecorationColor: theme.palette.primary.light,
        textDecorationThickness: "2px",
    },
    },
}));

const PopoverContent = ({ navigation }) => {

    const [expanded, setExpanded] = React.useState(null);
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Box sx={{
            width: "fit-content",
            marginLeft: "auto",
            marginRight: "auto",
        }}>
            <Box sx={{
                margin: "auto",
                padding: "30px",
                display: "inline-block"
            }}>
                <div>
                    {navigation?.navigation.sections.map((navItem, index) => {
                        if (navItem.links && navItem.links.length > 0) {
                            return <Accordion square expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                                <AccordionSummary aria-controls={index + "-content"} id={index + "-header"}>
                                    <Typography>{navItem.title}</Typography>
                                </AccordionSummary>
                                <StyledAccordionDetails>
                                    {navItem.links.map(sectionItem => {
                                        if (isMobile) return <HeaderSectionItem label={sectionItem.label} link={sectionItem.link} subtitle={sectionItem.subtitle} fullWidth centerText />
                                        return <HeaderSectionItem label={sectionItem.label} link={sectionItem.link} subtitle={sectionItem.subtitle} />
                                    })}
                                </StyledAccordionDetails>
                            </Accordion>
                        }
                        return <Accordion square expanded={expanded === `panel${index}`} onChange={() => navigate(navItem.pageLink)}>
                            <AccordionSummary aria-controls={index + "-content"} id={index + "-header"}>
                                <Typography>{navItem.title}</Typography>
                            </AccordionSummary>
                        </Accordion>
                    })}
                </div>
            </Box>
        </Box>
    )
}

export default PopoverContent
