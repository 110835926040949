import React from "react";

import SEO from "../components/seo"
import Container from "../layout/container"
import Layout from "../layout/layout"
import Hero from "../layout/hero";
import DynamicZone from "../cms/dynamicZone/pages/index";
import { MapNavigation, MapFooter } from "../cms/mapper/index";

const PageTemplate = ({ classes, pageContext }) => {

    const { langKey, pagePath,seoImageURL, title, subTitle, backgroundImage, sections, navigation,footer,contentImages } = pageContext
    MapNavigation(navigation?.navigation,langKey)
    MapFooter(footer?.footer_links,langKey)
    console.log(JSON.stringify(seoImageURL))
    return (
        <Layout langKey={langKey} navigation={navigation} footer={footer}>
            <SEO title={title} image={seoImageURL} />
            <Hero img={backgroundImage} title={title} subTitle={subTitle} />
            <Container>
                <DynamicZone sections={sections} contentImages={contentImages} />
            </Container>
        </Layout>
    );
}


export default PageTemplate
