import React from "react"
import MenuIcon from "@mui/icons-material/Menu"
import {
  IconButton, Popover, Typography
} from "@mui/material"
import PopoverContent from "./popoverContent";

const MobileHeader = ({ navigation }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const divRef = React.useRef();


  const handleClick = (event) => {
    setAnchorEl(divRef);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let anchorPos = process.env.GATSBY_COMPONENTS_APPBAR_HEIGHT ?? "90px"
  anchorPos = anchorPos.replace("px","")
  return <>
    <IconButton
      color={process.env.GATSBY_COMPONENTS_APPBAR_TEXT_COLOR ?? "inherit"}
      aria-label="Open drawer"
      onClick={handleClick}
      sx={{
        position: "relative",
        marginLeft: "auto",
        marginRight: "10%",
        marginTop: "auto",
        marginBottom: "auto",

      }}
      size="large">
      <MenuIcon />
    </IconButton>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      anchorReference="anchorPosition"
      anchorPosition={{ top: anchorPos, left: 0 }}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <PopoverContent navigation={navigation} />
    </Popover>
  </>;
}

export default MobileHeader
