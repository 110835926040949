import React from "react"
import { Box, Typography } from "@mui/material"
import RichText from "../strapiRichText/index";

import { styled } from "@mui/system";

const RootBox = styled("div")(({ theme }) => ({
  marginTop: "48px",
  [theme.breakpoints.down('lg')]: {
    marginTop: "40px",
  }
}));


const SectionContainer = ({ children, head, text, marginTop }) => {
  return <RootBox sx={{marginTop:marginTop}}>
    <Box textAlign={"center"}>
      {head ? (
        <Typography sx={{marginBottom:"40px"}} variant="h2">
          {head}
        </Typography>) : <></>
      }
    </Box>
    <Typography variant={"body1"} sx={{marginBottom:"40px"}}>
      <RichText>
        {text}
      </RichText>
    </Typography>
    {children}
  </RootBox>
}

export default SectionContainer
