import React from "react"

// Components
import { Timeline } from "@mui/lab";
import TimeLineEvent from "./timeLineEvent";
import SectionContainer from "../../layout/SectionContainer";
import { useTheme, useMediaQuery } from "@mui/material";



const TimeLine = ({ timeLineItems, header }) => {
    const theme = useTheme()
    const isTablet = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <SectionContainer head={header}>
            <Timeline  position={isTablet ? "left" :"alternate"}>
                {timeLineItems.map(item => {
                    return <TimeLineEvent description={item.description} image={item.image} time={item.time} title={item.title} />
                })}
            </Timeline>
        </SectionContainer>
    );
}

export default TimeLine