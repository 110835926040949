import React from "react"

// Components
import {
    Typography, Box, Grid, Card, MobileStepper, CardActionArea, Divider, Hidden
} from "@mui/material"
import { greyColor } from "../../../theme/theme";
import { StaticImage } from "gatsby-plugin-image";
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import { styled } from '@mui/system';

const Option = styled(Card)(({ theme }) => ({
    width: "240px",
    height: "270px",
    marginBottom: "30px",
    backgroundColor: greyColor
}));

const ImageWrapper = styled("div")(({ theme }) => ({
    padding: "40px 0",
        textAlign: "center"
}));

const Text = styled("div")(({ theme }) => ({
    textAlign: "center",
        paddingTop: "10px",
        paddingBottom: "10px",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
}));

const MarkertingWrapper = styled(Grid)(({ theme }) => ({
    minHeight: "50px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
}));

//.MuiLinearProgress-barColorPrimary
const ValuationStepper = () => {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const imageSrc=""

    return <><Card sx={{
        position: "relative",
        overflow: "hidden",
    }}>
        <Typography variant="h3"><Box textAlign="center" padding="40px">Einfamilienhaus, Wohnung oder Mehrfamilienhaus?</Box></Typography>
        <Grid container justifyContent="center" alignContent="space-between">
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} container justifyContent="center">
                <Option>
                    <CardActionArea>
                        <ImageWrapper>
                            <StaticImage src={imageSrc}  layout="fixed" width={150} height={150} />
                        </ImageWrapper>
                        <Typography><Text>Einfamilienhaus</Text></Typography>
                    </CardActionArea>
                </Option>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} container justifyContent="center">
                <Option>
                    <CardActionArea>
                        <ImageWrapper>
                            <StaticImage src={imageSrc}  layout="fixed" width={150} height={150} />
                        </ImageWrapper>
                        <Typography><Text>Wohnung</Text></Typography>
                    </CardActionArea>
                </Option>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} container justifyContent="center">
                <Option>
                    <CardActionArea>
                        <ImageWrapper>
                            <StaticImage src={imageSrc} layout="fixed" width={150} height={150} />
                        </ImageWrapper>
                        <Typography><Text>Mehrfamilienhaus</Text></Typography>
                    </CardActionArea>
                </Option>
            </Grid>
        </Grid>
        <Grid container justifyContent="center" alignContent="space-between" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container justifyContent="center" alignItems="center" sx={{
                  height: "90px",
                  backgroundColor: theme => theme.palette.primary.main,
                  color: theme => theme.palette.primary.contrastText,
            }}>
                <MobileStepper
                    aria-label="progress"
                    variant="progress"
                    steps={6}
                    position="static"
                    activeStep={activeStep}
                    sx={{
                        flexGrow: 1,
                        backgroundColor: theme => theme.palette.primary.main,
                    }}
                    nextButton={
                        <Button sx={{color: theme => theme.palette.primary.contrastText}} size="small" onClick={handleNext} disabled={activeStep === 5}>
                            Next
                            {<KeyboardArrowRight />}
                        </Button>
                    }
                    backButton={
                        <Button sx={{color: theme => theme.palette.primary.contrastText}} size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {<KeyboardArrowLeft />}
          Back
        </Button>
                    }
                />
            </Grid>
        </Grid>
        <Divider />
        <MarkertingWrapper container justifyContent="center" alignContent="space-between" alignItems="center">
            <Hidden only={["xxs","xs","sm"]}><MarkertingWrapper item xs={12} sm={12} md={4} lg={3} xl={3} container justifyContent="center" alignItems="center">
                <Box display='flex' alignItems='center' flexWrap='wrap'>
                    <CheckIcon fontSize='small' />&nbsp; In nur wenigen Schritten
                </Box>
            </MarkertingWrapper>
            <MarkertingWrapper item xs={12} sm={12} md={4} lg={3} xl={3} container justifyContent="center" alignItems="center">
                <Box display='flex' alignItems='center' flexWrap='wrap'>
                    <CheckIcon fontSize='small' />&nbsp; Erfahrene Makler vor Ort
                </Box>
            </MarkertingWrapper>
            </Hidden>
            <MarkertingWrapper item xs={12} sm={12} md={4} lg={3} xl={3} container justifyContent="center" alignItems="center">
                <Box display='flex' alignItems='center' flexWrap='wrap'>
                    <CheckIcon fontSize='small' />&nbsp; Kostenlose Immobilienbewertung
                </Box>
            </MarkertingWrapper>
        </MarkertingWrapper>

    </Card>
    </>;
}

export default ValuationStepper
