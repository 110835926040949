import React from "react"
import { Link } from "gatsby"
import { useIntl } from "react-intl"

const LocalizedLink = ({ariaLabel, id, defaultMessage, children }) => {
  const intl = useIntl()
  const config = {
    id: id,
    defaultMessage: defaultMessage,
  }
  if (!intl) {
    return <Link aria-label={ariaLabel} to={config.id}>{children}</Link>
  }
  return <Link aria-label={ariaLabel} to={intl.formatMessage(config)}>{children}</Link>
}

export default LocalizedLink
