import React from "react";

import { Box, Typography, Button, Hidden, Link as MuiLink } from "@mui/material";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import StrapiImage from "../../strapiImage/index";
import { styled } from '@mui/system';


// Import Swiper styles
import 'swiper/css';
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/effect-fade"


// import Swiper core and required modules
import SwiperCore, {
    Navigation,
    Pagination,
    EffectFade,
    Autoplay,
    Parallax
} from 'swiper';

// install Swiper modules
SwiperCore.use([EffectFade, Navigation, Pagination, Autoplay, Parallax]);


const SlideWrapper = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
        top: "60%",
    },
    [theme.breakpoints.down("sm")]: {
        top: "65%",
    },
    top: "65%",
    textAlign: "center",
    position: "absolute",
    zIndex: "1000",
    width: "100%",
    color: "white"
}));



const Slider = ({ slides }) => {
    return (
        <Swiper
            spaceBetween={50}
            slidesPerView={1}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            navigation={true}
            pagination={{
                "clickable": true
            }}
            loop={true}
            effect={'fade'}
            autoplay={{
                "delay": 6500,
                "disableOnInteraction": false
            }}
            style={{ '--swiper-navigation-color': '#fff', '--swiper-pagination-color': '#fff' }}
            parallax={true}
        >
            {slides.map(slide => {
                return <SwiperSlide>
                    <SlideWrapper>
                        <Box sx={{ backgroundColor: "rgba(255,255,255,0.8)", padding: {xxs: "20px",md: "30px" }, width: { md: "50%" } }} display="inline-block">
                            <div className="title" data-swiper-parallax="-300">
                                <Typography sx={{ fontSize: { xxs: "16px", xs: "20px", md: "34px" } }} variant="h1" color="primary">
                                    {slide.title}
                                </Typography>
                            </div>
                            <Hidden smDown>
                                <Box sx={{ marginTop: "10px" }}>
                                    <div className="subtitle" data-swiper-parallax="-200">
                                        <Typography variant="subtitle" color="secondary">
                                            {slide.subtitle}
                                        </Typography>
                                    </div>
                                </Box>
                            </Hidden>
                            <Box sx={{ marginTop: "10px" }}>
                                <div className="text" data-swiper-parallax="-100">
                                    <MuiLink href={slide.link} >
                                        <Button variant="contained">{slide.buttontext}</Button>
                                    </MuiLink>
                                </div>
                            </Box>

                        </Box>
                    </SlideWrapper>
                    <Box sx={{ maxWidth: "1960px", width: "100%", margin: "0 auto", position: "relative" }}>
                        <StrapiImage image={slide.image} sxImage={{ minHeight: "475px", maxHeight: "100vh" }} />
                    </Box>
                </SwiperSlide>
            })}
        </Swiper>
    );
}


export default Slider