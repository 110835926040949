import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { FormattedMessage } from "react-intl";
import {
  Typography,
  Paper,
  Grid,
  Hidden,
  Divider,
  Box,
} from "@mui/material"
import Container from "../layout/container";
import CloudIcon from '@mui/icons-material/Cloud';
import DynamicZone from "../cms/dynamicZone/footer/index";
import LanguageDropdown from "../components/layout/languageDropdown";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

//Breakpoints in sx:
//backgroundColor: { xs: "secondary.light", sm: "#0000ff" }


const SubFooter = ({ footerLinks }) => {
  if (!footerLinks) {
    return <></>
  }
  return <>
    {footerLinks.map(link => {
      return (
        <Grid item xs={6} sm={3} md={2} lg={2} xl={2} container justifyContent="center" alignItems="center" sx={{ zIndex: 950 }}>
          <Link to={link.pageLink}>
            <Typography variant="caption" sx={{
              color: theme => theme.palette.primary.contrastText,
              textTransform: "uppercase",
              lineHeight: "48px",
              zIndex: 1250,
            }}>
              {link.label}
            </Typography>
          </Link>
        </Grid>
      );

    })}
  </>;
}

const Footer = ({ classes, footer, removeFooterMargin }) => {
  const footerLinks = footer?.footer_links
  const footerSections = footer?.footer_abschnitte

  return (
    <Paper sx={removeFooterMargin ? {
      paddingTop: theme => theme.spacing(6),
      backgroundColor: theme => theme.palette.primary.main,
    } : {
      paddingTop: theme => theme.spacing(6),
      marginTop: theme => theme.spacing(12),
      backgroundColor: theme => theme.palette.primary.main,
    }}>
      <Container>
        {footer?.footer_abschnitte && footer?.footer_abschnitte.length > 0 ? (<>
          <Grid
            container
            justifyContent="center"
            spacing={2}
          >
            {footerSections.map(section => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4} container justifyContent="center" alignItems="flex-start" sx={{ marginBottom: "30px" }}>
                  <Zoom>
                    <DynamicZone footerSection={section} />
                  </Zoom>
                </Grid>
              );
            })}
          </Grid>
          <Box marginTop="20px" />
          <Divider variant="middle" component="li" />
          <Box marginTop="20px" />
        </>) : <></>}
        {process.env.GATSBY_LANG_ENABLED === "true" ? (
          <Hidden mdUp>
            <Grid container justifyContent="center" alignItems="center" sx={{
              width: "200px",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "30px",
            }}>
              <Typography sx={{ margin: "auto", color: theme => theme.palette.primary.contrastText }}><FormattedMessage id="footer.lang" defaultMessage="Sprache" />:</Typography>
              <LanguageDropdown />
            </Grid>
            <Box marginTop="20px" />
            <Divider variant="middle" component="li" />
            <Box marginTop="20px" />
          </Hidden>
        ) : (
          <></>
        )}
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={1} lg={2} xl={2}>
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={8} xl={8} container justifyContent="center" alignItems="center">
            <SubFooter classes={classes} footerLinks={footerLinks} />
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={2} xl={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container justifyContent="center" alignItems="center">
              <Typography variant="caption" sx={{ color: theme => theme.palette.primary.contrastText, textTransform: "uppercase" }}>Powered by <a href="https://www.p6k.de" target="_blank">P6K</a></Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
}

export default Footer
