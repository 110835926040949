import React from "react"
import RichText from "../../strapiRichText/index";

// Components
import { Typography, Button } from "@mui/material"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { Link } from "gatsby";

import { styled } from '@mui/system';

const RootWrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    paddingRight: "50px",
  }
}));

const TextBox = ({ title, description, buttonText, buttonLink }) => {
  if (!buttonLink) {
    buttonLink = "/de"
  }

  return <RootWrapper>
    <Typography variant="h3">{title}</Typography>
    <br></br>
    <Typography variant="body1">
      <RichText>
        {description}
      </RichText>
    </Typography>
    {buttonText && buttonLink ? (
      <Link to={buttonLink}>
        <Button
          variant="text"
          color="primary"
          sx={{marginTop: "5%"}}
          endIcon={<ArrowForwardIosIcon />}
        >
          {buttonText}
        </Button>
      </Link>) : <></>}
  </RootWrapper>
}

export default TextBox
