import React from "react"
import SectionContainer from "../../layout/SectionContainer";
import { createMarkup } from "../utils";
import { useMediaQuery } from "@mui/material";
import { maxWidth  } from "../../../theme/theme";




const GoogleMapsIframe = ({ iframe }) => {

    let replaceWith = `width="${maxWidth}"`
    if (useMediaQuery('(max-width:1500px)')) {
        replaceWith = 'width="1200"'
    }
    if (useMediaQuery('(max-width:1200px)')) {
        replaceWith = 'width="1100"'
    }
    if (useMediaQuery('(max-width:1100px)')) {
        replaceWith = 'width="1000"'
    }
    if (useMediaQuery('(max-width:1000px)')) {
        replaceWith = 'width="900"'
    }
    if (useMediaQuery('(max-width:900px)')) {
        replaceWith = 'width="800"'
    }
    if (useMediaQuery('(max-width:800px)')) {
        replaceWith = 'width="700"'
    }
    if (useMediaQuery('(max-width:700px)')) {
        replaceWith = 'width="600"'
    }
    if (useMediaQuery('(max-width:600px)')) {
        replaceWith = 'width="500"'
    }
    if (useMediaQuery('(max-width:500px)')) {
        replaceWith = 'width="400"'
    }
    if (useMediaQuery('(max-width:400px)')) {
        replaceWith = 'width="300"'
    }


    //<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d466.67013476968907!2d7.81206992307977!3d48.57375258659032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4796ced5e8ce4ed5%3A0x50b86d1a680f96aa!2sImmobiliengruppe%20R.G.%20Br%C3%BCning%20Immobilien%20GmbH!5e0!3m2!1sde!2sde!4v1616224511708!5m2!1sde!2sde" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    const resizeMap = (iframeString) => {
        if (!iframeString) {
            return
        }
        const search = 'width\=\"([A-Za-z0-9 _]*)\"';
        const searchRegExp = new RegExp(search, 'g');
        const result = iframeString.replace(searchRegExp, replaceWith)
        return result
    }


    return (
        <SectionContainer>
            <div dangerouslySetInnerHTML={createMarkup(resizeMap(iframe))} />
        </SectionContainer>
    )
}

export default GoogleMapsIframe

