import React from "react"
import {  Accordion, AccordionSummary, AccordionDetails, Typography,Box } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { greyColor } from "../../../theme/theme";
import { createMarkup } from "../../../utils/utils";

const FAQ = ({ faqList }) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box sx={{width: '100%'}}>
            {faqList?.map((element, index) => {
                return <Accordion expanded={expanded === index} onChange={handleChange(index)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id={`panel1bh-header-${index}`}
                    >
                        <Typography sx={{
                            fontSize: theme => theme.typography.pxToRem(15),
                            flexBasis: '100%',
                            flexShrink: 0,
                            color: theme => theme.palette.text.secondary,
                            paddingLeft: "20px",
                        }}>{element.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        backgroundColor: greyColor,
                        paddingTop: "20px",
                        paddingBottom: "20px"
                    }}>
                        <Typography sx={{
                             color: theme => theme.palette.text.secondary,
                             paddingLeft: "20px",
                        }}>
                            <div dangerouslySetInnerHTML={createMarkup(element.answer)} />
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            })}
        </Box>
    )
}

export default FAQ


