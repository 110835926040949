import React from "react"

// Components
import SectionContainer from "../../layout/SectionContainer";
import Quote from "./quote";

const BlockQuote = ({ text, author, image  }) => {
    return (
        <SectionContainer>
            <Quote text={text} author={author} image={image} />
        </SectionContainer>
    );
}

export default BlockQuote
