import React from "react"
import { Paper } from "@mui/material"
import { styled } from "@mui/system";


const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: "3rem",
    [theme.breakpoints.down('md')]: {
      padding: "1.5rem",
    },
}));

const ContentPaper = props => {
  const { children } = props
  return <StyledPaper>{children}</StyledPaper>
}

export default ContentPaper
