import React from "react"
import SectionContainer from "../../layout/SectionContainer";
import ContactForm from "./contact";


const Contact = ({ header, text, showQuickText }) => {

    return (
        <SectionContainer head={header} text={text}>
           <ContactForm showQuickText={showQuickText} showMessage={false} showPlaceholder={false} />
        </SectionContainer>
    )
}

export default Contact

