import React from "react"
import SelectedLanguageContextProvider from "./src/context/languageContext"
import LanguageSwitcher from "./src/context/languageSwitcher"
import ErrorBoundaryWrapper from "./src/context/errorBoundary/errorBoundary"

const WrapPageElement = ({ children }) => {
  return (
    <SelectedLanguageContextProvider>
      <LanguageSwitcher>
        <ErrorBoundaryWrapper>{children}</ErrorBoundaryWrapper>
      </LanguageSwitcher>
    </SelectedLanguageContextProvider>
  )
}

export default WrapPageElement
