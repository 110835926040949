import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { getImage } from "gatsby-plugin-image"
import RichText from "../../../components/strapiRichText/index";
// Components
import { TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent, TimelineDot } from "@mui/lab";
import { Typography, useTheme, useMediaQuery, Box } from "@mui/material";
import { styled } from '@mui/system';


const StyledTimelineContent = styled(TimelineContent)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        flex: "350"
    },
}));

const StyledGatsbyImage = styled(GatsbyImage)(({ theme }) => ({
    height: 300,
    [theme.breakpoints.down('lg')]: {
        height: 300,
    },
    marginBottom: "10px"
}));

const TimeLineEvent = ({ title, description, time, image }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    return <TimelineItem>
        <TimelineOppositeContent>
        {!isMobile ? (<Typography variant="h3" color="textSecondary" component="p">
                    {time}
                </Typography>):<></>}
        </TimelineOppositeContent>
        <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
        </TimelineSeparator>
        <StyledTimelineContent>
            <Typography variant="h3" component="p" style={{ marginBottom: "10px" }}>
                {title}
            </Typography>
            {isMobile ? (
                <Typography variant="subtitle1" component="p" style={{ marginBottom: "10px" }}>
                    {time}
                </Typography>) : <></>}
            <StyledGatsbyImage image={getImage(image?.localFile)} />
            <Typography variant="body1" style={{ textAlign: "initial", marginBottom: "20px" }}>
                <RichText>
                    {description}
                </RichText>
            </Typography>
        </StyledTimelineContent>
    </TimelineItem>
}

export default TimeLineEvent
