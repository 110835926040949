import React from "react"

// Components
import SectionContainer from "../../layout/SectionContainer";
import Reference from "./reference";
import { Grid } from "@mui/material";


const References = ({ header, references }) => {
    return (
        <SectionContainer head={header}>
            <Grid container spacing={10} alignItems="center" >
                {references?.map((element, index) => {
                    return <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Reference caption={element.caption} name={element.name} title={element.title} rating={element.rating / 2} description={element.description} city={element.city} image={element.image} />
                    </Grid>
                })}
            </Grid>        
            </SectionContainer>
    );
}

export default References
