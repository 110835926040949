import React from "react"
import { Link } from "gatsby";
// Components
import {
    Typography,
    Card,
    Button,
    Box,
    useMediaQuery,
    IconButton,
    useTheme,    //   Hidden,
    Link as MuiLink,
} from "@mui/material"
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { FormattedMessage } from "react-intl"
import { fontWeightBold } from "../../../theme/theme";
import StrapiImage from "../../strapiImage/index";
import { styled } from '@mui/system';

const CardBody = styled('div')(({ theme }) => ({
    paddingLeft: "30px",
    [theme.breakpoints.down('sm')]: {
        textAlign: "center",
    }
}));


const Site = ({ city, street, zip, telephone, siteURL, mail, image, showSiteBeforeCity, showButton, placeTitleAbove, showContactInfo, cardBackgroundColor }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    //Table?
    return (
        <div className="clearfix">
            <div>
                <Box sx={{ marginBottom: "30px" }} key={city}>
                    <Card sx={{ border: "solid 1px rgba(0, 0, 0, 0.08)" }} variant="outlined">
                        <div>
                            <Box textAlign="center">
                                {placeTitleAbove ? <></> : <StrapiImage image={image} sxImageWrapper={{ height: "250px" }} />}
                                <Typography variant="h3">
                                    <Box fontWeight={fontWeightBold} paddingTop={3}>
                                        {showSiteBeforeCity ? <FormattedMessage id="branchOffice.branchOffice" defaultMessage="Standort" /> : <></>} {city}
                                    </Box>
                                </Typography>
                            </Box>
                            <Box paddingTop={3} />
                            <CardBody>
                                <Box sx={{ minHeight: "50px" }}>
                                    <Typography variant="subtitle2" component="p">
                                        <Box color="primary.main">{street}</Box>
                                        <Box color="primary.main">{zip} {city}</Box>
                                    </Typography>
                                </Box>
                                <Box paddingTop={3} />
                                {showContactInfo && !isMobile ? (<Box color="text.grey">
                                    <Typography variant="subtitle2" component="p">
                                        <FormattedMessage id="branchOffice.tel" defaultMessage="Tel." />:<MuiLink sx={{
                                            textDecoration: 'none',
                                            color: theme.palette.text.grey,
                                            "&:hover": {
                                                color: theme.palette.primary.main,
                                                textDecoration: 'underline'
                                            },
                                        }} href={"tel:" + telephone}>{" " + telephone}</MuiLink>
                                    </Typography>
                                    <Typography variant="subtitle2" component="p">
                                        <FormattedMessage id="branchOffice.mail" defaultMessage="E-Mail" />: <MuiLink sx={{
                                            textDecoration: 'none',
                                            color: theme.palette.text.grey,
                                            "&:hover": {
                                                color: theme.palette.primary.main,
                                                textDecoration: 'underline'
                                            },
                                        }} href={"mailto:" + mail}>{" " + mail}</MuiLink>
                                    </Typography>
                                </Box>) : <></>
                                }
                            </CardBody>
                            {showContactInfo && isMobile ? (<Box textAlign="center">
                                <Box component="a" sx={{
                                    textDecoration: 'none',
                                    color: theme.palette.text.grey,
                                    "&:hover": {
                                        color: theme.palette.primary.main,
                                        textDecoration: 'underline'
                                    },
                                }} href={"mailto:" + mail}>
                                    <IconButton aria-label="delete" size="large">
                                        <EmailIcon />
                                    </IconButton>
                                </Box>
                                <MuiLink sx={{
                                    textDecoration: 'none',
                                    color: theme.palette.text.grey,
                                    "&:hover": {
                                        color: theme.palette.primary.main,
                                        textDecoration: 'underline'
                                    },
                                }} href={"tel:" + telephone}>
                                    <IconButton aria-label="delete" size="large">
                                        <PhoneIcon />
                                    </IconButton>
                                </MuiLink>
                            </Box>) : <></>
                            }
                            <Box paddingTop={3} />
                            {placeTitleAbove ? <StrapiImage image={image} sxImageWrapper={{ height: "250px" }} /> : <></>}
                            {showButton ? (<Link to={siteURL}>
                                <Button fullWidth variant="contained"><FormattedMessage id="branchOffice.button" defaultMessage="zum Standort" /></Button>
                            </Link>) : <></>}
                        </div>
                    </Card>
                </Box>
            </div>
        </div>
    );
}

export default Site
