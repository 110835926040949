import { useStaticQuery, graphql } from "gatsby"


export const MapGallerie = (component) => {
    const data = useStaticQuery(graphql`{
        allStrapiGallery {
            nodes {
              bilder {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH,formats: [AUTO,WEBP,AVIF], placeholder: TRACED_SVG)
                  }
                }
              }
            }
          }
        }
        `)

    component.Bilder = data.allStrapiGallery?.nodes[0]?.bilder
}

