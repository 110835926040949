import React from "react"

// Components
import {
    Typography, Box, Grid
} from "@mui/material"
import PartnerLogo from "./partnerLogo";

import { styled } from "@mui/system";

const TextContainer = styled("div")(({ theme }) => ({
    textAlign: "left",
        [theme.breakpoints.down('sm')]: {
            textAlign: "center",
        },
}));




const Partner = ({ name, logo, description, link }) => {
    return <>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3} container justifyContent="center">
            <PartnerLogo name={name} logo={logo} link={link} />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <TextContainer>
                <Typography variant="h3">
                    {name}
                </Typography>
                <Box color="text.grey">
                    <Typography variant="body1" component="p">
                    {description}
                    </Typography>
                </Box>
            </TextContainer>
        </Grid>
    </>;
}

export default Partner
