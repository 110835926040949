import React from "react"

// Components
import SectionContainer from "../../layout/SectionContainer";
import ValuationStepper from "./valuationStepper";


const Valuation = ({ header,text }) => {
    return (
        <SectionContainer head={header} text={text}>
            <ValuationStepper />
        </SectionContainer>
    );
}

export default Valuation
