import React from "react"
import { MenuItem, Typography, Box } from "@mui/material";
import { Link } from "gatsby";

import { styled } from "@mui/system";


const StyledLink = styled(Link)(({ theme }) => ({
    padding: "10px",
    textDecoration: "none",
}));

const HeaderSectionItem = ({  label, subtitle, link, fullWidth, centerText }) => {
    return (
        <StyledLink to={link} style={fullWidth ? {width: "100%"} : {width: "50%"}}>
            <MenuItem style={{ minHeight: "90px" }} sx={centerText ? {textAlign: "center"} : {}}>
                <Box sx={{ width: "100%" }}>
                    <Typography color="primary">{label}</Typography>
                    <Typography color="textSecondary" sx={{
                        overflow: "auto",
                        whiteSpace: "normal",
                        textOverflow: "unset",
                        fontSize: "14px"
                    }}>{subtitle}</Typography>
                </Box>
            </MenuItem>
        </StyledLink>
    )
}

export default HeaderSectionItem
