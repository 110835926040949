import React from "react"

// Components
import {
    Grid
} from "@mui/material"
import SectionContainer from "../../layout/SectionContainer";
import Partner from "./partner";
import { styled } from "@mui/system";

const CustomSectionContainer = styled(SectionContainer)(({ theme }) => ({
    paddingTop: "1px",
    paddingBottom: "50px"
}));



const PartnerList = ({ header, partners }) => {
    return <CustomSectionContainer head={header}>
        <Grid container spacing={3} alignItems="center" >
            {partners.map(partner => {
                return <Partner description={partner.description} link={partner.link} name={partner.name} logo={partner.logo} />
            })}
        </Grid>
    </CustomSectionContainer>
}

export default PartnerList
