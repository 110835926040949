import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { Typography, useMediaQuery, Box } from "@mui/material"
import { styled } from "@mui/system";

const ReducedHightImage = styled(GatsbyImage)(({ theme }) => ({
  maxHeight: "500px",
  minHeight: "375px",
}));

const StyledGatsbyImage = styled(GatsbyImage)(({ theme }) => ({
  maxHeight: "750px",
  minHeight: "80vh",
  "@media (max-width: 600px)": {
    maxHeight: "550px",
  },
  "@media (max-width: 400px)": {
    maxHeight: "350px",
  },
}));


const HeroTitle = styled(Typography)(({ theme }) => ({
  position: "absolute",
  //width: "900px",
  left: "40%",
  top: "85%",
  //transform: "translate(-50%, 0)",
  backgroundColor: theme.palette.primary.transparent,

  //backgroundColor: "hsla(350,50%,50%,0.3)",
  "&:focus, &:hover, &$active": {
    backgroundColor: theme.palette.primary.main,
  },
  transition: "background 1s",
  color: "white",
  [theme.breakpoints.down('lg')]: {
    //width: "80%",
    fontSize: "3rem",
  },
  [theme.breakpoints.down('md')]: {
    //width: "90%",
    fontSize: "2.5rem",
  },
  [theme.breakpoints.down('sm')]: {
    //width: "90%",
    fontSize: "1.9rem",
  },
}));

const HeroContent = ({ title }) => (
  <div>
    <HeroTitle variant="h1" color="inherit">
      {title}
    </HeroTitle>
  </div>
)

// const NonStretchedImage = props => {
//   let normalizedProps = props
//   const height = props.h || 200
//   const maxWidth = props.maxWidth || 3000
//   if (props.fluid) {
//     normalizedProps = {
//       ...props,
//       style: {
//         ...(props.style || {}),
//         maxWidth,
//         height,
//         margin: "0 auto", // Used to center the image
//       },
//     }
//   }

//   return <Img {...normalizedProps} />
// }

const Background = ({ img, w, reduceMinHeight }) => {
  const maxWidth = w || "1360px"

  return (
    <div>
      {img ? (
        <Box sx={{
          width: "100%",
          //maxWidth: "1600px",
          margin: "0 auto",
          //filter: "brightness(40%)",
          zIndex: -1,
          position: "relative"
        }} style={{ maxWidth }}>
          {
            reduceMinHeight ? (
              <ReducedHightImage image={img.gatsbyImageData} loading="eager" placeholder="blurred" />
            ) : (
              <StyledGatsbyImage image={img.gatsbyImageData} loading="eager" placeholder="blurred" />
            )
          }
        </Box>
      ) : (
        <div
          style={{
            width: "100%",
            background: "linear-gradient(to left, #00c9ff, #92fe9d)",
          }}
        />
      )}
    </div>
  );
}

const Hero = ({ img, title, subTitle, w, heroTitle, reduceMinHeight }) => {

  const maxWidth = w || "1600px"

  return <>
    <Box sx={{
      position: "relative",
      overflow: "hidden",
      marginTop:process.env.GATSBY_COMPONENTS_APPBAR_HEIGHT ?? "90px"
    }}>
      <Background img={img} w={w} reduceMinHeight={true} />
      <HeroContent title={heroTitle} />
    </Box>
    <Box sx={{
      padding: "10px",
      transition: "background 1s",
      color: "black",
      margin: "30px auto",
      textAlign: "center"
    }} style={{ maxWidth }}>
      <Typography sx={{
        textTransform: "uppercase",
        color: theme => theme.palette.text.grey,
      }} variant="body1" component="h2">{subTitle}</Typography>
      <Typography variant="h3" component="h1"><Box color="primary.main" fontWeight={500}>{title}</Box></Typography>
    </Box>
  </>
}

export default Hero
