/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import WrapPageElement from "./wrapPageElement"

// Wraps every page in a component
export const wrapPageElement = ({ element }) => (
  <WrapPageElement>{element}</WrapPageElement>
)
