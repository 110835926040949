import React from "react"

// Components
import SectionContainer from "../../layout/SectionContainer";
import Contact from "./contact";

const SiteInfo = ({ header, text, telephone, mail, city, street, zip, opening_hours,google_link }) => {
    return (
        <SectionContainer head={header} text={text}>
            <Contact telephone={telephone} mail={mail} city={city} street={street} zip={zip} opening_hours={opening_hours} google_link={google_link} />
        </SectionContainer>
    );
}

export default SiteInfo
