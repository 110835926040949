import React from "react"

// Components
import SectionContainer from "../../layout/SectionContainer";
import FAQ from "./faq";


const FAQs = ({ header, text, faqs }) => {
    return (
        <SectionContainer head={header} text={text}>
            <FAQ faqList={faqs} />
        </SectionContainer>
    );
}

export default FAQs
