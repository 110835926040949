import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types"
import { Hidden, AppBar, Box } from "@mui/material"
import MobileHeader from "./mobileHeader/index"
import DesktopHeader from "./dektopHeader/index"
import SubHeader from "./subHeader/subHeader"
import LocalizedLink from "../../components/lang/localizedLink"


//TODO: Isolate as component
const Header = ({ navigation }) => {
  const data = useStaticQuery(graphql`{
    allStrapiGlobal {
      nodes {
        locale
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 150, layout: FIXED)
            }
          }
        }
      }
    }
  }
  `)

  const logo = data.allStrapiGlobal.nodes[0].logo.localFile.childImageSharp
  return <>
    <AppBar color="appBar">
      {process.env.GATSBY_LANG_ENABLED === "true" ? (
        <Hidden only={["xxs", "xs", "sm"]}>
          <SubHeader />
        </Hidden>
      ) : (
        <></>
      )}
      {/* Display menu and drawer only on small devices */}
      <Hidden only={["md", "lg", "xl"]}>
        <MobileHeader logo={logo} navigation={navigation} />
      </Hidden>
      {/* Display header logo on all devices */}
      <Box sx={{
        marginLeft: "10%",
        paddingTop: process.env.GATSBY_COMPONENTS_LOGO_PADDING_TOP ?? "0px",
        position: "fixed",
        zIndex: "9",
      }}>
        <LocalizedLink ariaLabel="Logo" id="link.index" defaultMessage="/de">
          <GatsbyImage image={logo.gatsbyImageData} loading="lazy" />
        </LocalizedLink>
      </Box>
      <Hidden only={["xxs", "xs", "sm"]}>
        <DesktopHeader navigation={navigation} />
      </Hidden>
    </AppBar>

    <div id="back-to-top-anchor" />
  </>;
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default Header
