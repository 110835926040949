import React from "react"

// Components
import {
    Card, Box, CardContent
} from "@mui/material"
import StrapiImage from "../../strapiImage/index";


const Partner = ({ name, logo, link }) => {
    return (
        <a href={link} target="_blank" aria-label={name} rel="noopener">
            <Card sx={{
                position: "relative",
                width: "200px",
                height: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <CardContent aria-label="partnerLogo">
                    <Box textAlign="center">
                        <Box paddingTop="20px" />
                        <Box sx={{
                            paddingLeft: "30px",
                            paddingRight: "30px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                            <StrapiImage image={logo} sxImageWrapper={{
                                height: "auto",
                                margin: 0,
                                padding: 0,
                                maxWidth: "100%",
                            }} />
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </a>
    );
}

export default Partner
