import React from "react"
import { FormattedMessage } from "react-intl";
import { Box } from "@mui/material";
// Components
import LanguageDropdown from "../../../components/layout/languageDropdown"

const SubHeader = () => {
  return (
    <Box sx={{
      position: "absolute",
      width: "120px",
      right: "10%",
      textAlign: "center",
      display: "inline-flex"
    }}>
      <Box sx={{
        margin: "auto",
        fontSize: "12px"
      }}><FormattedMessage id="footer.lang" defaultMessage="Sprache" />:</Box>
      <LanguageDropdown />
    </Box>
  )
}

export default SubHeader
