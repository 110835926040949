import React from "react"
import { useSelectedLanguage } from "../../context/languageContext"
import { FlagIcon } from "../../assets/flags";

// Components
import { FormControl, Select, MenuItem, Typography } from "@mui/material"


const LanguageDropdown = () => {
  // Select
  const {
    setSelectedLanguage,
    selectedLanguage,
    availableLanguages,
  } = useSelectedLanguage()

  const renderMenuItems = () => {
    let menuItems = []
    availableLanguages.forEach(lang => {
      menuItems.push(
        <MenuItem value={lang.key} key={lang.key}>
          <Typography variant="caption">{lang.text}</Typography>
        </MenuItem>
      )
    })
    return menuItems
  }

  // SelectChange
  const handleLanguageChange = event => {
    setSelectedLanguage(event.target.value)
  }

  if (selectedLanguage === "") {
    setSelectedLanguage('de')
  }

  console.log("SelectedLANG: " + selectedLanguage)

  return (
    <FormControl fullWidth>
      <Select
        id="buy-simple-select"
        value={selectedLanguage}
        onChange={handleLanguageChange}
        sx={{
          color: "white",
          textAlign: "center",
          fontSize: "16px"
        }}
        variant="standard"
      >
        {availableLanguages.map(lang => {
          return <MenuItem sx={{color: "black"}} value={lang.key} key={lang.key}>
            <FlagIcon langKey={lang.key} />
          </MenuItem>
        })}
      </Select>
    </FormControl>
  )
}

export default LanguageDropdown
