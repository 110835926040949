import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useSelectedLanguage } from "./languageContext"
import { navigate } from "gatsby"
import { IntlProvider } from "react-intl"
import translations from "../lang"
import { useStaticQuery, graphql } from "gatsby"
import _ from "lodash";


const LanguageSwitcher = ({ children }) => {
  const { selectedLanguage, setSelectedLanguage } = useSelectedLanguage()
  const { allPageLink } = useStaticQuery(
    graphql`
      query {
        allPageLink {
          nodes {
            link
            pageID
          }
        }
      }
    `
  )

  useEffect(() => {
    const langPath = window.location.pathname.substring(1, 3)
    if (!selectedLanguage) {
      setSelectedLanguage(langPath)
    } else {
      const pageKey = _.find(allPageLink.nodes, ['link', window.location.pathname])?.pageID
      allPageLink.nodes.map(page => {
        const languageKey = page.link.substring(1, 3)
        if (page.pageID === pageKey && languageKey === selectedLanguage) {
          console.log("Navigate To" + JSON.stringify(page.link))
          navigate(page.link)
        }
      })
    }
  }, [selectedLanguage])

  return (
    <IntlProvider
      defaultLocale={'de'}
      locale={selectedLanguage}
      messages={translations[selectedLanguage]}
    >
      {children}
    </IntlProvider>
  )
}

LanguageSwitcher.propTypes = {
  children: PropTypes.object.isRequired,
}

export default LanguageSwitcher
