import React from "react"

import { MapZeitstrahl, MapAccordion } from "../../mapper/index";
import { MapGallerie } from "../../mapper/gallery";


// Components

// Basic
import Partner from "../../../components/basic/partner/index";
import TimeLine from "../../../components/basic/timeLine/index";
import CustomerReviews from "../../../components/basic/customerReviews/index";
import TeamOverview from "../../../components/basic/team/index";
import Contact from "../../../components/basic/contact/index";
import TextImageBox from "../../../components/text/textImageBox/index";
import Sites from "../../../components/basic/sites/index";
import GoogleMapsIframe from "../../../components/basic/googleMapsIframe/index";
import SiteInfo from "../../../components/basic/siteInfo/index";
import FAQ from "../../../components/text/faq/index";
import Gallery from "../../../components/basic/gallery/index";

// Real Estate
import Valuation from "../../../components/real-estate/valuation/index";
import References from "../../../components/real-estate/references/index";

// Text
import Quote from "../../../components/text/blockquote/index";
import TextBox from "../../../components/text/textBox/index";
import Accordion from "../../../components/text/accordion/index";



const DynamicZone = ({ sections, contentImages }) => {
    return (
        sections?.map(component => {
            switch (component.strapi_component) {

                case "slices.partner":
                    component.partner.map(element => {
                        element.description = element.untertitel
                        element.name = element.titel
                    })
                    return <Partner header={component.titel} partners={component.partner} />
                case "slices.zeitstrahl":
                    // component.ereignisse.map(element => {
                    //     element.description = element.text
                    //     element.title = element.titel
                    //     element.image = element.bild
                    //     element.time = element.zeitpunkt
                    // })
                    MapZeitstrahl(component.ereignisse, contentImages)
                    return <TimeLine header={component.titel} timeLineItems={component.ereignisse} />
                case "slices.kundenstimmen":
                    component.kundenstimmen.map(element => {
                        element.description = element.text
                        element.ranking = element.bewertung
                        element.image = element.bild
                    })
                    return <CustomerReviews header={component.titel} customerReviews={component.kundenstimmen} />
                case "slices.kontakt":
                    return <Contact header={component.titel} showQuickText={component.shortcut} text={component.text} />
                case "slices.google-maps-iframe":
                    return <GoogleMapsIframe iframe={component.iframe} />
                case "slices.standort-info":
                    return <SiteInfo city={component.site?.ort} mail={component.site?.mail} header={component.header} text={component.text} zip={component.site?.plz} street={component.site?.strasse} telephone={component.site?.telefon} google_link={component.site?.google_link} opening_hours={component.site?.servicezeit} />
                case "slices.gallerie":
                    MapGallerie(component)
                    return <Gallery header={component.titel} text={component.text} images={component.Bilder} />

                // ------------------------   Text ------------------------------------
                case "text.faq":
                    component.faqs.map(element => {
                        element.question = element.frage
                        element.answer = element.antwort
                    })
                    return <FAQ header={component.titel} text={component.text} faqs={component.faqs} />
                case "text.text-bild-box":
                    component.abschnitte.map(element => {
                        element.imagePosition = element.bildposition
                        element.image = element.bild
                        element.title = element.titel
                    })
                    return <TextImageBox header={component.titel} text={component.text} boxes={component.abschnitte} />
                case "text.zitat":
                    return <Quote text={component.text} author={component.autor} image={component.bild} />
                case "text.text-box":
                    return <TextBox header={component.titel} text={component.text} />
                case "text.accordion":
                    MapAccordion(component.sections)
                    return <Accordion header={component.titel} text={component.text} sections={component.sections} />


                // ------------------------   Real Estate ------------------------------------
                case "real-estate.wertermittlung":
                    return <Valuation header={component.titel} text={component.text} />

                case "real-estate.referenzen":
                    component.referenzen.map(element => {
                        element.title = element.titel
                        element.description = element.beschreibung
                        element.city = element.ort
                        element.name = element.name
                        element.caption = element.bildunterschrift
                        element.rating = element.bewertung
                        element.image = element.bild
                    })
                    return <References header={component.titel} references={component.referenzen} />


            }
        })
    );
}

export default DynamicZone

