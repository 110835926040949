import React from "react"
import { Grid, Card, Box, Typography } from "@mui/material";
import Rating from '@mui/material/Rating'
import StarBorderIcon from "@mui/icons-material/StarBorder"
import { fontWeightBold } from "../../../theme/theme";
import StrapiImage from "../../strapiImage/index";
import { styled } from '@mui/system';

const Quote = styled('div')(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    minHeight: "150px",
  },
  [theme.breakpoints.down('lg')]: {
    marginBottom: "10px"
  },

  fontStyle: "italic",
}));

export default function CustomerReview({
  description,
  name,
  ranking,
  image,
}) {

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={10} sm={5} md={6} lg={6} xl={6} container justifyContent="center">
        <StrapiImage sxImageWrapper={{
          width: "200px",
          height: "250px",
          margin: "auto",
          border: "solid 6px transparent",
          borderRadius: "10%",
        }} image={image} />
      </Grid>
      <Grid item xs={10} sm={5} md={6} lg={6} xl={6} container justifyContent="center">
        <Box textAlign="left" fontWeight={fontWeightBold} paddingBottom="10px" >{name}</Box>
        <Typography variant="body2" color="primary" component="p">
          <Quote>"{description}"</Quote>
        </Typography>
        <Typography variant="body2" component="p">
          <Box textAlign="left">
            <Rating
              name="customized-empty"
              value={ranking}
              precision={0.5}
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
              readOnly={true}
              size="large"
            />
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );
}
