import HouseIcon from "@mui/icons-material/House"
import EuroIcon from "@mui/icons-material/Euro"
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter"
import IconPeople from "@mui/icons-material/People"
import slugify from "slugify";


export function encodePrettyUri(toBeEncoded) {
  let encoded = encodeURI(toBeEncoded)
  let searchRegExp = /%20/g
  encoded = encoded.replace(searchRegExp, "-")
  return encoded
}

export function encodeImmoUri(title, objectID,locale) {
  const merged = title+" "+objectID
  return "/" + locale + "/" + slugify(merged, { lower: true });
}

export const debounce = (delay, fn) => {
  let timeout

  return function (...args) {
    if (timeout) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(() => {
      fn(...args)
      timeout = null
    }, delay)
  }
}

export const createMarkup = (text) => {
  return { __html: text }
}

export const mapMaterialIcon = (iconKey) => {
  switch (iconKey) {
    case "HouseIcon":
      return HouseIcon
    case "EuroIcon":
      return EuroIcon
    case  "BusinessCenterIcon":
      return BusinessCenterIcon
    case "IconPeople":
      return IconPeople    
    default:
      return HouseIcon
  }
}
