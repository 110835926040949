import React from "react"

// Components
import {
  Grid,
} from "@mui/material"
import TeamMember from "./teamMember";
import SectionContainer from "../../layout/SectionContainer";


const TeamOverview = ({ team,header }) => {
  return (
    <SectionContainer head={header}>
    <Grid container spacing={4}>
      {team.map(member => {
        return <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <TeamMember academicTitle={member.academicTitle} languages={member.languages} mail={member.mail} mobile={member.mobile} firstName={member.firstName} name={member.name} image={member.image} education={member.education} position={member.position} showAdditionalInfo={true} />
        </Grid>
      })}
    </Grid>
    </SectionContainer>
  );
}

export default TeamOverview
