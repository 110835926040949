import React from "react"

import LinkedInIcon from "@mui/icons-material/LinkedIn"
import FacebookIcon from "@mui/icons-material/Facebook"
import TwitterIcon from "@mui/icons-material/Twitter"
import InstagramIcon from '@mui/icons-material/Instagram';
import {
    Typography,
    Grid,
    IconButton,
    Box,
  } from "@mui/material"


const SocialMedia = ({ header, links }) => {
    return <>
    <Grid container justifyContent="center" spacing={3} alignItems="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container justifyContent="center">
        <Typography>
          <Box color="primary.contrastText">{header}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container justifyContent="center">
        {links.linkedin_link ? (
          <a href={links.linkedin_link} >
            <IconButton aria-label="linkedin" size="large">
              <Box color="primary.contrastText">
                <LinkedInIcon  fontSize="large" sx={{
                  transition: "transform 0.15s ease-in-out",
                  "&:hover": { transform: "scale3d(1.15, 1.15, 1)" },
                }} />
              </Box>
            </IconButton></a>) : <></>}
        {links.facebook_link ? (
          <a href={links.facebook_link} target="_blank" >
            <IconButton aria-label="facebook" size="large">
              <Box color="primary.contrastText">
                <FacebookIcon fontSize="large" sx={{
                  transition: "transform 0.15s ease-in-out",
                  "&:hover": { transform: "scale3d(1.15, 1.15, 1)" },
                }} />
              </Box>
            </IconButton></a>) : <></>}
            {links.instagram_link ? (
          <a href={links.instagram_link} target="_blank" >
            <IconButton aria-label="instagram" size="large">
              <Box color="primary.contrastText">
                <InstagramIcon fontSize="large" sx={{
                  transition: "transform 0.15s ease-in-out",
                  "&:hover": { transform: "scale3d(1.15, 1.15, 1)" },
                }} />
              </Box>
            </IconButton></a>) : <></>}
        {links.twitter_link ? (
          <a href={links.twitter_link} target="_blank" >
            <IconButton aria-label="twitter" size="large">
              <Box color="primary.contrastText">
                <TwitterIcon  fontSize="large" sx={{
                  transition: "transform 0.15s ease-in-out",
                  "&:hover": { transform: "scale3d(1.15, 1.15, 1)" },
                }} />
              </Box>
            </IconButton></a>) : <></>}
      </Grid>
    </Grid>
  </>;
}

export default SocialMedia

    
