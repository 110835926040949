import React from "react"

// Components
import {
    Typography, Box, Grid
} from "@mui/material"
import { fontWeightBold } from "../../../theme/theme";
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import StrapiImage from "../../strapiImage/index";
import { styled } from '@mui/system';

const StyledQuote = styled("blockquote")(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        minHeight: "150px",
        //marginBottom: "50px"
    },
    [theme.breakpoints.down('lg')]: {
        marginBottom: "20px"
    },
    fontSize: "24px",
    fontStyle: "italic",
}));

const Quote = ({ classes, author, image, text }) => {
    return (
        <Grid direction="row" justifyContent="center" alignItems="center" spacing="4" container >
            <Grid item xs={6} sm={4} md={3} lg={3}>
                <StrapiImage sxImageWrapper={{
                     height: "auto",
                     maxHeight: "400px",
                     margin: 0,
                     padding: 0,
                     maxWidth: "100%",
                }} image={image} />
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9}>
                <Typography variant="body1" color="primary" component="p">
                    <StyledQuote><FormatQuoteIcon />{text}</StyledQuote>
                </Typography>
                <Box textAlign="left" fontWeight={fontWeightBold} marginLeft="40px" paddingTop="10px" >{author}</Box>
            </Grid>

        </Grid>
    );
}

export default Quote





