import React from "react"


// Components
import {
    Grid,
    Box,
    Typography,
    useTheme,
    useMediaQuery,
    Link as MuiLink
} from "@mui/material"
import { fontWeightBold } from "../../../theme/theme";
import { FormattedMessage } from "react-intl"
import { styled } from '@mui/system';


const CustomGridItem = styled(Grid)({
    marginTop: "20px",
    marginBottom: "20px"
});

const Contact = ({ telephone, mail, city, street, zip, opening_hours, google_link }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    return (
        <Grid container spacing={2} sx={{ textAlign: "center" }} >
            <CustomGridItem item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Typography variant="h3">
                    <Box fontWeight={fontWeightBold} paddingBottom={isMobile ? 2 : 3}>
                        <FormattedMessage id="stickyarea.contactShort" defaultMessage="Kontakt" />
                    </Box>
                </Typography>
                Telefon:<MuiLink href={"tel:" + telephone}>{" " + telephone}</MuiLink><br />
                E-Mail: <MuiLink href={"mailto:" + mail}>{" " + mail}</MuiLink><br />
                {google_link ? <MuiLink href={google_link} target="_blank">Web</MuiLink> : <></>}
            </CustomGridItem>
            <CustomGridItem item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Typography variant="h3">
                    <Box fontWeight={fontWeightBold} paddingBottom={isMobile ? 2 : 3}>
                        <FormattedMessage id="contact.address" defaultMessage="Anschrift" />
                    </Box>
                </Typography>
                {street}<br />
                {zip + " " + city}<br />
            </CustomGridItem>
            <CustomGridItem item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Typography variant="h3">
                    <Box fontWeight={fontWeightBold} paddingBottom={isMobile ? 2 : 3}>
                        <FormattedMessage id="branchOffice.openingHours" defaultMessage="Öffnungszeiten" />
                    </Box>
                </Typography>
                {opening_hours}
            </CustomGridItem>
        </Grid>
    );
}

export default Contact
