import React from "react"

// Components
import SectionContainer from "../../layout/SectionContainer";
import AccordionSections from "./accordion";


const Accordion = ({ header, text, sections }) => {
    return (
        <SectionContainer head={header} text={text}>
            <AccordionSections sections={sections} />
        </SectionContainer>
    );
}

export default Accordion
