import React from "react"

// Components
import SectionContainer from "../../layout/SectionContainer";
import RichText from "../../strapiRichText/index";


const TextBox = ({ header,text }) => {
    return (
        <SectionContainer head={header}>
            <RichText>
                {text}
            </RichText>
        </SectionContainer>
    );
}

export default TextBox
