import React from "react"

import { useEffect, useState } from "react"
import { ErrorBoundary } from "react-error-boundary"
import ErrorFallback from "./errorFallback"

const ERROR_STATE_LOCAL_STORAGE = "error_state"
const ERROR_SEND_INTERVAL = 5000 //sending errors only every x milliseconds

const ErrorBoundaryWrapper = ({ children }) => {
  const [newErrors, setNewErrors] = useState(false)

  useEffect(() => {
    window.addEventListener("unhandledrejection", ev => {
      handleError(ev.reason)
    })
    setNewErrors(true)
  }, [])

  useEffect(() => {
    if (!newErrors) {
      return
    }
    setNewErrors(false)
    const currentErrorState = getErrorState()
    const currentTimeStamp = new Date().getTime()
    if (currentErrorState.lastSubmit < currentTimeStamp - ERROR_SEND_INTERVAL) {
      const request = {
        report: {
          app: "SomeApp",
          uri: window.location.href,
          userData: {
            userAgent: navigator.userAgent,
            os: navigator.platform,
          },
          error: currentErrorState.errors,
        },
      }
      //invoke api
      console.log("INVOKE: " + JSON.stringify(request))
      console.log("Error: " + JSON.stringify(request.report.error))
      if (request.report.error && request.report.error.length > 0) {
        let url = window.location.protocol + "//" + window.location.hostname + "/.netlify/functions/error"
        if(window.location.hostname.includes("localhost")) {
          url = window.location.protocol + "//" + window.location.hostname +":8888"+ "/.netlify/functions/error"
        }
        fetch(url, {
          method: "POST",
          body: JSON.stringify(request),
          headers: { "Content-type": "application/json; charset=UTF-8" }
        }).then(response => response.json())
          .then(json => console.log(json))
          .catch(err => console.log(err));
      }

      currentErrorState.lastSubmit = currentTimeStamp
      currentErrorState.errors = []
      setErrorState(currentErrorState)
    } else {
      setTimeout(() => {
        setNewErrors(true)
      }, 5000)
    }
  }, [newErrors])

  const getErrorState = () => {
    const localStorageErrorState = window.localStorage.getItem(
      ERROR_STATE_LOCAL_STORAGE
    )
    let currentErrorState = {
      errors: [],
      lastSubmit: 0,
    }
    if (localStorageErrorState) {
      currentErrorState = JSON.parse(localStorageErrorState)
    }
    return currentErrorState
  }

  const setErrorState = newErrorState => {
    window.localStorage.setItem(
      ERROR_STATE_LOCAL_STORAGE,
      JSON.stringify(newErrorState)
    )
  }

  const handleError = err => {
    if (!err || !err.message) {
      return
    }

    const currentErrorState = getErrorState()
    const errorObj = {
      message: err.message,
      stack: err.stack ? err.stack.toString() : "",
    }
    currentErrorState.errors.push(errorObj)
    setErrorState(currentErrorState)
    setNewErrors(true)
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
      {children}
    </ErrorBoundary>
  )
}

export default ErrorBoundaryWrapper
