import React from "react"

import Markdown from 'react-markdown'
import  Gfm from "remark-gfm";
import {  Typography } from "@mui/material";


const RichText = ({ children }) => {

    return <Markdown
    //transformLinkUri={uri=>console.log("URI: "+uri)}
    remarkPlugins={[Gfm]}
        components={{
            // Use h2s instead of h1s
            h1: ({node, ...props}) => <Typography variant="h1" sx={{marginBottom:"20px"}} {...props} />,
            h2: ({node, ...props}) => <Typography variant="h2" sx={{marginBottom:"20px"}} {...props} />,
            h3: ({node, ...props}) => <Typography variant="h3" {...props} />,
            h4: ({node, ...props}) => <Typography variant="h4" {...props} />,
            h5: ({node, ...props}) => <Typography variant="h4" {...props} />,
            h6: ({node, ...props}) => <Typography variant="h4" {...props} />,
            blockquote: ({node, ...props}) => <i style={{color: 'red'}} {...props} />
        }}
    >
        {children}
    </Markdown>

}

export default RichText
