import React from "react"
import { Button, Box, Grid, Typography } from "@mui/material"
import CustomerReview from "./customerReview";
import SectionContainer from "../../layout/SectionContainer";


const CustomerReviews = ({ header, customerReviews }) => {
    return (
        <SectionContainer head={header}>
            <Grid container spacing={10} alignItems="center" >
                {customerReviews.map((element, index) => {
                    return <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <CustomerReview description={element.description} name={element.name} ranking={element.ranking / 2} image={element.image} />
                    </Grid>
                })}
            </Grid>
        </SectionContainer>
    )
}

export default CustomerReviews

