import React from "react"
import { fontWeightBold } from "../../../theme/theme";

// Components
import {
    Typography,
    Card,
    //   CardMedia,
    Box,
    IconButton,
    //   Hidden,
} from "@mui/material"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import FacebookIcon from "@mui/icons-material/Facebook"
import TwitterIcon from "@mui/icons-material/Twitter"
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { FlagIcon } from "../../../assets/flags";
import { styled } from '@mui/system';

const MediaTextWrapper = styled('div')(({ theme }) => ({
    position: "absolute",
    width: "100%",
    textAlign: "right",
    fontSize: "28px",
    display: "flex",
    justifyContent: "center",
}));

const StyledGatsbyImage = styled(GatsbyImage)(({ theme }) => ({
    width: "200px",
    height: "250px",
    margin: "auto",
    border: "solid 6px transparent",
    borderRadius: "10%",
}));

const TeamMember = ({ mail, mobile, firstName, name, image, education, position, showAdditionalInfo, languages, academicTitle }) => {
    let fullName = firstName + " " + name
    if (academicTitle) {
        fullName = academicTitle + ". " + firstName + " " + name
    }

    //Table?
    return (
        <div className="clearfix">
            <div>
                <Box sx={{ marginBottom: "30px" }} key={name}>
                    <Card sx={{
                        padding: "30px",
                        border: "solid 1px rgba(0, 0, 0, 0.08)"
                    }} variant="outlined">
                        <div>
                            <Box textAlign="center">
                                <div style={{ position: "relative" }}>
                                    <StyledGatsbyImage image={getImage(image?.localFile)} />
                                    {languages?.map((lang, index) => {
                                        const topPercent = 90 - ((index + 1) * 15)
                                        return (
                                            <MediaTextWrapper style={{ top: `${topPercent}%` }} >
                                                <Box sx={{
                                                    width: "200px",
                                                    cursor: "default"
                                                }}>
                                                    <FlagIcon langKey={lang.code} />
                                                </Box>
                                            </MediaTextWrapper>
                                        )
                                    })}
                                </div>
                                <Typography variant="h3">
                                    <Box fontWeight={fontWeightBold}>
                                        {fullName}
                                    </Box>
                                </Typography>
                                <Box paddingTop={3} />
                                {showAdditionalInfo ? (<>
                                    <Typography variant="subtitle2" component="p">
                                        <Box color="text.grey">{position}</Box>
                                    </Typography>
                                    <Box paddingTop={3} />
                                    <Box minHeight={50}>
                                        {
                                            education?.map(entry => {
                                                return <Typography variant="subtitle2" component="p">
                                                    {entry.text}
                                                </Typography>
                                            })
                                        }
                                    </Box>
                                    <Box paddingTop={3} /></>) : (<></>)}
                                <div>
                                    <Box component="a" sx={{
                                        color: theme => theme.palette.text.grey,
                                        "&:focus, &:hover, &$active": {
                                            color: theme => theme.palette.primary.main,
                                        },
                                    }} href={"mailto:" + mail}>
                                        <IconButton aria-label="delete" size="large">
                                            <EmailIcon />
                                        </IconButton>
                                    </Box>
                                    <Box component="a" sx={{
                                        color: theme => theme.palette.text.grey,
                                        "&:focus, &:hover, &$active": {
                                            color: theme => theme.palette.primary.main,
                                        },
                                    }} href={"tel:" + mobile}>
                                        <IconButton aria-label="delete" size="large">
                                            <PhoneIcon />
                                        </IconButton>
                                    </Box>
                                    <IconButton aria-label="delete" size="large">
                                        <LinkedInIcon />
                                    </IconButton>
                                    <IconButton aria-label="delete" size="large">
                                        <FacebookIcon />
                                    </IconButton>
                                    <IconButton aria-label="delete" size="large">
                                        <TwitterIcon />
                                    </IconButton>
                                </div>
                            </Box>

                        </div>
                    </Card>
                </Box>
            </div>
        </div>
    );
}

export default TeamMember
