import React from "react"
import { styled } from "@mui/system";


const StyledContainer = styled("div")(({ theme }) => ({
  margin: "0 auto",
  maxWidth: theme.maxWidth,
  [theme.breakpoints.up("sm")]: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}));


const Container = ({ children }) => (
  <StyledContainer>{children}</StyledContainer>
)

export default Container
