import React from "react"

// Components
import { Grid } from "@mui/material";
import SectionContainer from "../../layout/SectionContainer";
import Site from "./site";

const Sites = ({ header, text, sites }) => {
    return (
        <SectionContainer head={header} text={text}>
            <Grid container spacing={2} justifyContent="center">
                {sites.map(element => {
                    return <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Site city={element.city} image={element.image} mail={element.mail} zip={element.zip} siteURL={element.siteURL} street={element.street} telephone={element.telephone} showSiteBeforeCity={true} placeTitleAbove={false} cardBackgroundColor="white"  showContactInfo={true} showButton={true} />
                    </Grid>
                })}
            </Grid>
        </SectionContainer>
    );
}

export default Sites
