import React from "react"

import { MapStandorte } from "../../mapper/index";

// Components

// Basic
import TextBox from "../../../components/footer/textBox";
import SocialMedia from "../../../components/footer/socialMedia";
import Bilder from "../../../components/footer/bilder";



const DynamicZone = ({ footerSection }) => {
    switch (footerSection.strapi_component) {
        case "footer.social-media":
            footerSection.title = footerSection.titel
            return <SocialMedia header={footerSection.title} links={footerSection.links} />
        case "footer.bilder":
            footerSection.title = footerSection.titel
            footerSection.bilder.map(image=>{
                image.image = image.bild
            })
            return <Bilder header={footerSection.title} images={footerSection.bilder} />
        case "text.text-box":
            footerSection.title = footerSection.titel
            return <TextBox header={footerSection.title} text={footerSection.text} />
    }
}

export default DynamicZone

