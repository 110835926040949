import React from "react";

import { Typography, Box } from "@mui/material";
import SEO from "../../components/seo"
import Container from "../../layout/container"
import Layout from "../../layout/layout"
import { MapNavigation, MapFooter, MapCraftsmanLandingPage } from "../../cms/mapper/index";
import { fontWeightBold, backgroundImageColor } from "../../theme/theme";
import _ from "lodash";
import Zoom from "react-reveal/Zoom";
import PageSlider from "../../components/landingPage/slider/index";
import Services from "../../components/landingPage/services/index";
import CTA from "../../components/landingPage/cta/index";



const CraftsmanTemplate = ({ classes, pageContext }) => {

  const { langKey, pagePath, landingPage, navigation, footer } = pageContext
  const craftsmanPage = landingPage.theme[0]
  MapCraftsmanLandingPage(craftsmanPage)
  MapNavigation(navigation?.navigation, langKey)
  MapFooter(footer.footer_links, langKey)
  const { slider, contact, services } = craftsmanPage
  const marginTop = process.env.GATSBY_COMPONENTS_APPBAR_HEIGHT ?? "90px"

  return (
    <Layout langKey={langKey} navigation={navigation} footer={footer} removeFooterMargin >
      <SEO title={"Startseite"} image={slider?.Slide[0]?.image?.url} />
      <Box sx={{ marginTop: { xxs: marginTop, md: "0px" } }} />
      <PageSlider slides={slider.Slide} />
      <Services services={services} />
      <CTA text={contact.text} buttontext={contact.buttontext} link={contact.link} />
    </Layout>
  );
}

export default CraftsmanTemplate
