import React from "react"

import { Grid, Typography, Box } from "@mui/material";
import RichText from "../../components/strapiRichText/index";

const TextBox = ({ header, text }) => {
    return <>
        <Grid container justifyContent="center" spacing={3} alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container justifyContent="center">
                <Typography>
                    <Box color="primary.contrastText">
                        {header}
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container justifyContent="center">
                <Typography color="secondary">
                    <Box color="primary.contrastText">
                        <RichText>
                            {text}
                        </RichText>
                    </Box>
                </Typography>
            </Grid>
        </Grid>

    </>;
}

export default TextBox
