import React from "react"

// Components
import {
  Card,
  CardContent,
  Typography,
  ListItem,
  ListItemIcon,
  Box,
  useTheme,
  useMediaQuery
} from "@mui/material"
import StrapiImage from "../../strapiImage/index";
import LocationOnIcon from "@mui/icons-material/LocationOn"
import Rating from '@mui/material/Rating'
import StarBorderIcon from "@mui/icons-material/StarBorder"

import { styled } from '@mui/system';

const FactContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "2%",
  [theme.breakpoints.up("xs")]: {
    paddingRight: "10%",
    paddingLeft: "10%"
  }
}));


const EstateReference = ({
  title,
  description,
  city,
  name,
  image,
  caption,
  rating
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Card sx={{
      maxWidth: 580,
      margin: "auto"
    }}>
      <div>
        <div style={{ position: "relative" }}>
          <StrapiImage image={image[0]} />
          <Box sx={{
            position: "absolute",
            top: "50%",
            width: "100%",
            textAlign: "center",
            fontSize: "28px",
            display: "flex",
            justifyContent: "center",
            color: "white"
          }}>
            <Box sx={{
              background: "hsla(0, 0%, 28%, 0.46)",
              width: "200px",
              cursor: "default",
              transition: "background 1s"
            }}>
              {caption}
            </Box>
          </Box>
        </div>
        <CardContent>
          <ListItem>
          {isMobile ? <></>:(
            <ListItemIcon>
              <LocationOnIcon fontSize="small" />
              <Typography variant="body1" gutterBottom>
                <Box fontSize={16}>
                  {`${city} / ${name}`}
                </Box>
              </Typography>
            </ListItemIcon>)}
            <Box sx={{marginRight: {xxs:"auto",sm:"unset"},marginLeft: "auto"}}>
              <Typography variant="body2" component="p">
                <Box textAlign="left">
                  <Rating
                    name="customized-empty"
                    value={rating}
                    precision={0.5}
                    emptyIcon={<StarBorderIcon fontSize="inherit" />}
                    readOnly={true}
                    size="large"
                  />
                </Box>
              </Typography>
            </Box>
          </ListItem>
          <Typography color="primary" variant="h4" component="h2" sx={{
            fontWeight: "bold",
            paddingLeft: "20px",
            paddingTop: "5px"
          }}>
            <Box fontWeight={500}>
              {title}
            </Box>
          </Typography>
          <br></br>
          <Typography color="primary" variant="body1" component="p">
            {description}
          </Typography>
          <br></br>
          <FactContainer>

          </FactContainer>
          {isMobile ? (
            <ListItemIcon>
              <LocationOnIcon fontSize="small" />
              <Typography variant="body1" gutterBottom>
                <Box fontSize={16}>
                  {`${city} / ${name}`}
                </Box>
              </Typography>
            </ListItemIcon>):<></>}
        </CardContent>
      </div>
    </Card>
  );
}

export default EstateReference
