import React from "react"

// Components
import { Grid, Hidden } from "@mui/material"
import TextBox from "./textBox"
import StrapiImage from "../../strapiImage/index";
import { styled } from '@mui/system';

const GridContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(10),
  [theme.breakpoints.down('lg')]: {
    marginTop: theme.spacing(9),
  },
}));

const GridImageWrapper = styled(Grid)(({ theme }) => ({
  textAlign: "center",
  margin: "auto",
  maxWidth: "665px"
}));

const PaddingRight = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    paddingRight: "50px",
  }
}));


//TODO: internal and external link!
const TextImageBox = ({ boxes }) => {

  return (
    <GridContainer container spacing={11}>
      {boxes.map((element, index) => {
        if (element.imagePosition === "rechts" || element.imagePosition === "right") {
          return <>
            <Hidden mdDown>
              <Grid container style={{
                marginLeft: "20px",
                marginRight: "20px",
              }} sx={index === 0 ? {} : { marginTop: { xs: "40px", md: "48px" } }} spacing={6}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextBox
                    description={element.text}
                    title={element.title}
                    buttonText={element.buttontext}
                    buttonLink={element.buttonlink}
                  />
                </Grid>
                <GridImageWrapper item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <StrapiImage image={element.image} />
                </GridImageWrapper>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid container style={{
                marginLeft: "40px",
                marginRight: "10px",
              }} sx={index === 0 ? {} : { marginTop: { xs: "40px", md: "48px" } }} spacing={6}>
                <GridImageWrapper item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <StrapiImage image={element.image} />
                </GridImageWrapper>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextBox
                    description={element.text}
                    title={element.title}
                    buttonText={element.buttontext}
                    buttonLink={element.buttonlink}
                  />
                </Grid>
              </Grid>
            </Hidden>
          </>;
        } else {
          return <Grid container style={{
            marginLeft: "40px",
            marginRight: "10px",
          }} sx={index === 0 ? {}:{ marginTop: { xs: "40px", md: "48px" } }} spacing={6}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <PaddingRight>
                <StrapiImage image={element.image} />
              </PaddingRight>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextBox
                description={element.text}
                title={element.title}
                buttonText={element.buttontext}
                buttonLink={element.buttonlink}
              />
            </Grid>
          </Grid>
        }
      })}
    </GridContainer>
  );
}

export default TextImageBox
