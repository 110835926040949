import React from "react"

import SEO from "../../components/seo"
import WideContainer from "../../layout/wideContainer"
import ContentPaper from "../../layout/contentPaper"
import { Button, Typography } from "@mui/material"
import theme from "../../theme/theme"
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material"
import { AppBar, Box } from "@mui/material"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";


const ErrorFallback = ({ resetErrorBoundary }) => {
  const logo = ""
  const name = "Fehler"
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SEO title={name} />
        <AppBar color="appBar">
          {/* Display header logo on all devices */}
          <Box sx={{
            marginLeft: "10%",
            paddingTop: "20px",
            position: "fixed",
            zIndex: "9",
          }}
            onClick={() => window.location.assign("/")}
          >
            <GatsbyImage image={logo?.gatsbyImageData} />
          </Box>
        </AppBar>
        <WideContainer>
          <Box sx={{
            margin: "0 auto",
            maxWidth: "1300px",
            padding: "1rem 1rem",
          }}>
            <ContentPaper>
              <Typography variant="h2">Error</Typography>
              <Button onClick={resetErrorBoundary}>Erneut versuchen</Button>
              <Button onClick={() => window.location.assign("/")}>
                Zur Startseite
              </Button>
            </ContentPaper>
          </Box>
        </WideContainer>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default ErrorFallback
