import { createTheme } from "@mui/material/styles";
import { blue, green, red, grey } from "@mui/material/colors"

export const maxWidth = "1300px"
export const fontWeightBold = 500

//backgroundColor:
export const greyColor = "#eff0f1"
export const backgroundImageColor = "#dae3f2"

const defaultTheme = createTheme()
// A theme with custom primary and secondary color.
// It's optional.
const theme = createTheme({
  spacing: [0, 6, 10, 15, 20, 24, 28, 30, 32, 40, 48, 60, 64],
  maxWidth: maxWidth, //Container
  //rgba(14,79,154,0.88), #0e4f9a, #4e9cde;
  palette: {
    primary: {
      light: process.env.GATSBY_COLOR_PRIMARY_LIGHT ?? "#2196f3",
      main: process.env.GATSBY_COLOR_PRIMARY_MAIN ?? "#002664",
      dark: process.env.GATSBY_COLOR_PRIMARY_DARK ?? "#2c387e",
      contrastText: process.env.GATSBY_COLOR_PRIMARY_CONTRAST_TEXT ?? "#fff",
      transparent: process.env.GATSBY_COLOR_PRIMARY_TRANSPARENT ?? 'rgba(39,53,100,0.7)',
    },
    secondary: {
      light: process.env.GATSBY_COLOR_SECONDARY_LIGHT ?? "#eff1f1",
      main: process.env.GATSBY_COLOR_SECONDARY_MAIN ?? "#bdbebe",
      dark: process.env.GATSBY_COLOR_SECONDARY_DARK ?? "#8d8e8e",
      contrastText: process.env.GATSBY_COLOR_SECONDARY_CONTRAST_TEXT ?? "#000000",
    },
    appBar: {
      light: process.env.GATSBY_COLOR_APPBAR_LIGHT ?? "#fff",
      main: process.env.GATSBY_COLOR_APPBAR_MAIN ?? "#fff",
      dark: process.env.GATSBY_COLOR_APPBAR_DARK ?? "#fff",
      contrastText: process.env.GATSBY_COLOR_APPBAR_CONTRAST_TEXT ?? "#fff",
    },
    background: {
      default: "#f3f3f3"
    },
    grey: {
      main: "#eff0f1",
    },
    //tertiary: '#fff',
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    text: {
      primary: process.env.GATSBY_COLOR_TEXT_PRIMARY ?? "#002664",
      secondary: process.env.GATSBY_COLOR_TEXT_SECONDARY ?? "#000000",
      grey: 'rgba(0, 0, 0, 0.6)',
      contrastText:  process.env.GATSBY_COLOR_TEXT_CONTRAST_TEXT ?? "#fff",
    },
  },
  typography: {
    htmlFontSize: 10,
    fontSize: 10,
    useNextVariants: true,
    fontFamily: "Ubuntu",
  },
  breakpoints: {
    keys: ["xxs", "xs", "sm", "md", "lg", "xl"],
    values: {
      xxs: 0,
      xs: 400,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '#simple-popover > .MuiPaper-root': {
          minWidth: "100%",
          left: "0px!important"
        },
        'a': {
          textDecoration: 'none',
          '& hover': { textDecoration: 'underline' }
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "1.1rem",
          [defaultTheme.breakpoints.down('lg')]: {
            fontSize: "0.8rem",
          },
        },
      },

    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: process.env.GATSBY_COMPONENTS_APPBAR_HEIGHT ?? "90px",
        }
      },
    },
    MuiFab: {
      styleOverrides: {

        label: {
          textTransform: "none",
          fontSize: "1.2rem",
        },
      },

    },
    MuiTab: {
      styleOverrides: {

        label: {
          fontSize: "1.04rem",
        },
      },

    },
    MuiTypography: {
      styleOverrides: {

        h1: {  // Title on a index page
          fontWeight: 'bold',
          fontSize: "34px",
          lineHeight: "40px",
          [defaultTheme.breakpoints.down('lg')]: {
            fontSize: "25px",
            lineHeight: "30px",
          },
        },
        h2: { // Title on a section
          fontSize: "2.3rem",
          fontWeight: "400",
          lineHeight: "30px",
          [defaultTheme.breakpoints.down('lg')]: {
            fontSize: "1.8rem",
          },
          [defaultTheme.breakpoints.down('md')]: {
            fontSize: "1.6rem",
          },
        },
        h3: { // Title on a paragraph
          fontSize: "1.6rem",
          lineHeight: "30px",
          [defaultTheme.breakpoints.down('lg')]: {
            fontSize: "1.2rem",
            lineHeight: "20px",
          },
        },
        h4: { // secondary Title on a paragraph
          fontSize: "1.3rem",
          lineHeight: "30px",
          [defaultTheme.breakpoints.down('lg')]: {
            fontSize: "1.0rem",
            lineHeight: "20px",
          },
        },
        body1: {
          fontSize: "1.1rem",
          [defaultTheme.breakpoints.down('lg')]: {
            fontSize: "0.95rem",
          },
        },
        body2: {
          fontSize: "1.02rem",
          [defaultTheme.breakpoints.down('lg')]: {
            fontSize: "0.9rem",
          },
        },
        subtitle1: { // subTitle of a h1 or h2

        },
        subtitle2: { // subTitle of a Card Component e.g. Partner, Real Estate, Agent

        },
        caption: { // kleingedrucktes, fine print e.g. terms and conditions checkbox 

        },
      },

    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          '& hover': { textDecoration: 'underline' }
        },
      },

    },
    MuiLinearProgress: {
      styleOverrides: {
        barColorPrimary: {
          backgroundColor: "#fff",
        },
      },

    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingBottom: "0",
          alignItems: "baseline"
        },
      },

    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          // fontSize: "1rem",
          color: grey[600],
          [defaultTheme.breakpoints.down('lg')]: {
            fontSize: "0.8rem",
          },
        },
      },
    },
  },
  props: {},
})

export default theme
