import React from "react"
import { Stack } from "@mui/material"
import SectionContainer from "../../layout/SectionContainer";
import Masonry from '@mui/lab/Masonry';
import StrapiImage from "../../strapiImage/index";


const Gallery = ({ header, text, images }) => {
    return (
        <SectionContainer head={header} text={text}>
            <Masonry columns={3} spacing={1}>
                {images.map((item, index) => (
                    <Stack key={index}>
                        <StrapiImage
                            image={item}
                        />
                    </Stack>
                ))}
            </Masonry>
        </SectionContainer>
    )
}

export default Gallery

