import React from "react"
import HeaderSection from "./headerSection";
import { Box } from "@mui/material";
import { styled } from "@mui/system";


const OuterContainer = styled("div")(() => ({
  marginRight: "auto",
  marginLeft: "auto",
  marginTop: "40px",
}));

const DesktopHeader = ({ navigation }) => {
  return (
    <OuterContainer>
      <nav>
      <Box sx={{
        listStyleType: "none",
        margin: 0,
        padding: 0,
        display: "flex",
        flexDirection: "row",
        marginLeft: "200px",
        float: "right"
      }}>
        {navigation?.navigation.sections.map(navItem=>{
          return <HeaderSection navItem={navItem} />
        })}
      </Box>   
      </nav>  
    </OuterContainer>
  )
}

export default DesktopHeader