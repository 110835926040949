import React from "react"

import {
  Typography,
  Grid,
  Box,
} from "@mui/material"
import StrapiImage from "../../components/strapiImage/index";



const Bilder = ({ header, images }) => {
  return <>
    <Grid container justifyContent="center" spacing={3} alignItems="center">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container justifyContent="center">
        <Typography>
          <Box color="primary.contrastText">{header}
          </Box>
        </Typography>
      </Grid>
      {images?.map((image, index) => {
        return index < 6 ? (<Grid item xs={6} sm={4} md={4} lg={4} xl={4} container justifyContent="center">
          <a href={image.link} target="_blank">
            <Box sx={{
              position: "relative",
              width: "100px",
              height: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}>
              <StrapiImage image={image.image} />
            </Box>
          </a>
        </Grid>) : <></>;
      })}
    </Grid>
  </>;
}

export default Bilder


