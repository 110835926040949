import React, { useEffect, useState } from "react"
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormattedMessage } from "react-intl"

// Components
import { Card, TextField, Button, CardContent, Grid, FormControlLabel, Checkbox, Typography, FormGroup, Snackbar, Alert } from "@mui/material"

const validationSchema = yup.object({
  firstname: yup
    .string(<FormattedMessage id="contact.valid" defaultMessage="Bitte geben Sie eine valide Eingabe ein" />)
    .min(2, <FormattedMessage id="contact.valid" defaultMessage="Bitte geben Sie eine valide Eingabe ein" />)
    .required(<><FormattedMessage id="contact.firstname" defaultMessage="Vorname" />{" "} <FormattedMessage id="contact.required" defaultMessage="ist ein Pflichtfeld" /></>),
  lastname: yup
    .string(<FormattedMessage id="contact.valid" defaultMessage="Bitte geben Sie eine valide Eingabe ein" />)
    .min(2, <FormattedMessage id="contact.valid" defaultMessage="Bitte geben Sie eine valide Eingabe ein" />)
    .required(<><FormattedMessage id="contact.lastname" defaultMessage="Nachname" />{" "} <FormattedMessage id="contact.required" defaultMessage="ist ein Pflichtfeld" /></>),
  street: yup
    .string(<FormattedMessage id="contact.valid" defaultMessage="Bitte geben Sie eine valide Eingabe ein" />)
    .min(2, <FormattedMessage id="contact.valid" defaultMessage="Bitte geben Sie eine valide Eingabe ein" />),
  houseNumber: yup
    .string(<FormattedMessage id="contact.valid" defaultMessage="Bitte geben Sie eine valide Eingabe ein" />)
    .min(1, <FormattedMessage id="contact.valid" defaultMessage="Bitte geben Sie eine valide Eingabe ein" />),
  zip: yup
    .string(<FormattedMessage id="contact.valid" defaultMessage="Bitte geben Sie eine valide Eingabe ein" />)
    .min(1, <FormattedMessage id="contact.valid" defaultMessage="Bitte geben Sie eine valide Eingabe ein" />),
  city: yup
    .string(<FormattedMessage id="contact.valid" defaultMessage="Bitte geben Sie eine valide Eingabe ein" />)
    .min(1, <FormattedMessage id="contact.valid" defaultMessage="Bitte geben Sie eine valide Eingabe ein" />),
  telephone: yup
    .string(<FormattedMessage id="contact.valid" defaultMessage="Bitte geben Sie eine valide Eingabe ein" />)
    .min(1, <FormattedMessage id="contact.valid" defaultMessage="Bitte geben Sie eine valide Eingabe ein" />),
  email: yup
    .string(<FormattedMessage id="contact.valid" defaultMessage="Bitte geben Sie eine valide Eingabe ein" />)
    .email(<FormattedMessage id="contact.valid" defaultMessage="Bitte geben Sie eine valide Eingabe ein" />)
    .required(<><FormattedMessage id="contact.email" defaultMessage="Email*" />{" "} <FormattedMessage id="contact.required" defaultMessage="ist ein Pflichtfeld" /></>),
  terms: yup
    .bool()
    .oneOf([true], <FormattedMessage id="contact.required" defaultMessage="ist ein Pflichtfeld" />)
});

const Contact = ({ showQuickText, showMessage, showPlaceholder }) => {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  let placeholderText = ""
  let placeholderSubject = ''
  if (showPlaceholder) {
    placeholderText = "Ich interessiere mich für Ihr Angebot."
    placeholderSubject = 'Mein Interesse an Angebot Nr. XYZ'
  }
  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      street: '',
      houseNumber: '',
      email: '',
      city: '',
      telephone: '',
      zip: '',
      text: placeholderText,
      subject: placeholderSubject,
      terms: false,
      expose: false,
      visit: false,
      recall: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      window.grecaptcha.ready(_ => {
        window.grecaptcha
          .execute(process.env.GATSBY_RECAPTCHA_KEY, { action: "homepage" })
          .then(token => {
            values.recaptcha = token
            fetch(`/.netlify/functions/contact`, {
              method: `POST`,
              body: JSON.stringify(values),
              headers: {
                "content-type": `application/json`,
              },
            })
              .then(res => res.json())
              .then(body => {
                console.log(body)
                if (body === "ok") {
                  setOpenSnackbar(true);
                }
              }).catch(err => {
                if(Object.keys(err).length !== 0) {
                  console.error(JSON.stringify(err))
                  alert("Das hat leider nicht geklappt. Versuchen Sie es spätter erneut.")
                }
                setOpenSnackbar(true)
              })
          })
      })
    },
  });

  const handleLoaded = _ => {
    window.grecaptcha.ready(_ => {
      window.grecaptcha
        .execute(process.env.GATSBY_RECAPTCHA_KEY, { action: "homepage" })
        .then(token => {
          return
        })
    })
  }

  useEffect(() => {
    const isScriptExist = document.getElementById("recaptcha-key");
    if (!isScriptExist) {

      // Add reCaptcha
      const script = document.createElement("script")
      script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_KEY}`
      script.id = "recaptcha-key";

      script.addEventListener("load", handleLoaded)
      document.body.appendChild(script)
    }
  }, [])

  const [showAdditionalFields, setShowAdditionalFields] = useState(showMessage ? showMessage : false)
  //https://stackoverflow.com/questions/49389202/yup-doesnt-work-properly-with-i18n

  useEffect(() => {
    const textItem = " Bitte senden Sie mir ein Expose zu."
    if (formik.values.expose) {
      let text = formik.values.text
      text += textItem
      formik.setFieldValue("text", text)
    } else {
      let text = formik.values.text
      text = text.replace(textItem, "")
      formik.setFieldValue("text", text)
    }
  }, [formik.values.expose]);

  useEffect(() => {
    const textItem = " Ich würde die Immobilie gerne besichtigen."
    if (formik.values.visit) {
      let text = formik.values.text
      text += textItem
      formik.setFieldValue("text", text)
    } else {
      let text = formik.values.text
      text = text.replace(textItem, "")
      formik.setFieldValue("text", text)
    }
  }, [formik.values.visit]);

  useEffect(() => {
    const textItem = " Über einen Rückruf würde ich mich freuen."
    if (formik.values.recall) {
      let text = formik.values.text
      text += textItem
      formik.setFieldValue("text", text)
    } else {
      let text = formik.values.text
      text = text.replace(textItem, "")
      formik.setFieldValue("text", text)
    }
  }, [formik.values.recall]);

  useEffect(() => {
    if (showAdditionalFields) {
      return
    }
    if (formik.values.firstname !== "" || formik.values.lastname !== "" || formik.values.email !== "") {
      setShowAdditionalFields(true)
    }
  }, [formik.values.firstname]);

  return (
    <div>
      <div>
        <div>
          <Card
            sx={{ padding: "30px", border: "solid 1px rgba(0, 0, 0, 0.08)" }}
            variant="outlined">
            <CardContent>
              <form onSubmit={formik.handleSubmit}>
                <Grid direction="row" justifyContent="center" alignItems="center" spacing="30" container >
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="firstname"
                      name="firstname"
                      label={<FormattedMessage id="contact.firstname" defaultMessage="Vorname" />}
                      value={formik.values.firstname}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                      helperText={formik.touched.firstname && formik.errors.firstname}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="lastname"
                      name="lastname"
                      label={<FormattedMessage id="contact.lastname" defaultMessage="Nachname" />}
                      value={formik.values.lastname}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                      helperText={formik.touched.lastname && formik.errors.lastname}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="street"
                      name="street"
                      label={<FormattedMessage id="contact.street" defaultMessage="Straße" />}
                      value={formik.values.street}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={formik.touched.street && Boolean(formik.errors.street)}
                      helperText={formik.touched.street && formik.errors.street}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="houseNumber"
                      name="houseNumber"
                      label={<FormattedMessage id="contact.houseNumber" defaultMessage="Hausnummer" />}
                      value={formik.values.houseNumber}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={formik.touched.houseNumber && Boolean(formik.errors.houseNumber)}
                      helperText={formik.touched.houseNumber && formik.errors.houseNumber}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="zip"
                      name="zip"
                      label={<FormattedMessage id="contact.zip" defaultMessage="PLZ" />}
                      value={formik.values.zip}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={formik.touched.zip && Boolean(formik.errors.zip)}
                      helperText={formik.touched.zip && formik.errors.zip}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="city"
                      name="city"
                      label={<FormattedMessage id="contact.city" defaultMessage="Ort" />}
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={formik.touched.city && Boolean(formik.errors.city)}
                      helperText={formik.touched.city && formik.errors.city}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="telephone"
                      name="telephone"
                      label={<FormattedMessage id="contact.telephone" defaultMessage="Telefon" />}
                      value={formik.values.telephone}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={formik.touched.telephone && Boolean(formik.errors.telephone)}
                      helperText={formik.touched.telephone && formik.errors.telephone}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      label={<FormattedMessage id="contact.email" defaultMessage="Email*" />}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <div id="contact-form"></div>
                  {showAdditionalFields ? (
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          fullWidth
                          id="subject"
                          name="subject"
                          label={<FormattedMessage id="contact.subject" defaultMessage="Betreff" />}
                          value={formik.values.subject}
                          onChange={formik.handleChange}
                          variant="standard"
                          error={formik.touched.subject && Boolean(formik.errors.subject)}
                          helperText={formik.touched.subject && formik.errors.subject}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          fullWidth
                          id="text"
                          name="text"
                          label={<FormattedMessage id="contact.text" defaultMessage="Ihre Nachricht" />}
                          value={formik.values.text}
                          onChange={formik.handleChange}
                          variant="standard"
                          error={formik.touched.text && Boolean(formik.errors.text)}
                          helperText={formik.touched.text && formik.errors.text}
                          multiline
                        />
                      </Grid>
                      {showQuickText ? (
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox checked={formik.values.visit} onChange={() => formik.setFieldValue("visit", !formik.values.visit)} name="visit" />}
                            label={<FormattedMessage id="contact.visit" defaultMessage="Besichtigung" />}
                          />
                          <FormControlLabel
                            control={<Checkbox checked={formik.values.checked} onChange={() => formik.setFieldValue("expose", !formik.values.expose)} name="expose" />}
                            label={<FormattedMessage id="contact.expose" defaultMessage="Expose" />}
                          />
                          <FormControlLabel
                            control={<Checkbox checked={formik.values.recall} onChange={() => formik.setFieldValue("recall", !formik.values.recall)} name="recall" />}
                            label={<FormattedMessage id="contact.recall" defaultMessage="Rückruf" />}
                          />
                        </FormGroup>
                      ) : <></>}
                      <FormControlLabel
                        sx={{ marginTop: "20px" }}
                        control={<Checkbox checked={formik.values.terms} onChange={() => formik.setFieldValue("terms", !formik.values.terms)} name="checkedA" />}
                        label={<Typography variant="caption" >Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung meiner Anfrage erhoben und verarbeitet werden. Die Daten werden nach abgeschlossener Bearbeitung Ihrer Anfrage gelöscht. Detaillierte Informationen zum Umgang mit Nutzerdaten finden Sie in unserer <a href="/de/datenschutz">Datenschutzerklärung.</a> </Typography>}
                      />
                      <Typography sx={{ color: "#f44336", fontSize: "14px", position: "relative", width: "100%", textAlign: "center", fontWeight: "400" }}>
                        {formik?.errors?.terms && formik.touched.terms ? <FormattedMessage id="contact.termsRequired" defaultMessage="Bitte bestätigen Sie, dass Sie unsere Datenschutzerklärung zur Kenntnis genommen haben." /> : ""}
                      </Typography>
                    </>
                  ) : <></>}
                  <Typography sx={{ marginTop: "10px", position: "relative" }} variant="caption"><FormattedMessage id="contact.requiredFields" defaultMessage="Mit einem * gekennzeichnete Felder sind Pflichtfelder." /></Typography>
                  <Button color="primary" variant="contained" fullWidth sx={{ marginTop: "20px" }} type="submit">
                    <FormattedMessage id="contact.submit" defaultMessage="Absenden" />
                  </Button>
                </Grid>
              </form>
            </CardContent>

          </Card>
          <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
              Ihre Nachricht wurde erfolgreich übermittelt. Wir melden uns bei Ihnen!
            </Alert>
          </Snackbar>
        </div>
      </div>
    </div>
  );
}

export default Contact
