import React from "react"

// Components
import SectionContainer from "../../layout/SectionContainer";
import TextImageBoxes from "./textImageBox";


const TextImageBox = ({header,text,boxes}) => {
    return (
        <SectionContainer head={header} text={text}>
            <TextImageBoxes boxes={boxes} />
        </SectionContainer>
    );
}

export default TextImageBox
