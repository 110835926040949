import React from "react";

import { useMediaQuery,useTheme,Box, Typography, Button, Hidden, Link as MuiLink, Grid, Paper, Card, CardActionArea, CardMedia, CardContent } from "@mui/material";
import SectionContainer from "../../layout/SectionContainer";
import Container from "../../../layout/container"
import StrapiImage from "../../strapiImage/index";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";





const Services = ({ services }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    return (
        <Container>
            <SectionContainer head="Unsere Leistungen" marginTop="90px">
                <Grid container spacing={isMobile ? 6: 12}>
                    {services?.map(service => {
                        return <Grid item xxs="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <Zoom>
                                <Card elevation="6" square variant="elevation">
                                    <MuiLink href={service.link}>
                                        <CardActionArea>
                                            <CardMedia style={{ overflow: "hidden" }}>
                                                <StrapiImage sxImageWrapper={
                                                    {
                                                        height: 300,
                                                        transition: "transform 1s ease",
                                                        overflow: "hidden",
                                                        zIndex: 5,
                                                        "&:focus, &:hover": {
                                                            transform: "scale(1.5)",
                                                            opacity: 0.9
                                                        }
                                                    }
                                                } image={service.image} />
                                            </CardMedia>
                                        </CardActionArea>
                                    </MuiLink>
                                    <CardContent>
                                        <Fade>
                                            <Typography variant="h1" sx={{ padding: "20px", textAlign: "center" }}>
                                                {service.title}
                                            </Typography>
                                        </Fade>
                                    </CardContent>
                                    <Fade>
                                        <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
                                            {service.buttontext ? (
                                                <MuiLink href={service.link}>
                                                    <Button variant="outlined">{service.buttontext}</Button>
                                                </MuiLink>) : <></>}
                                        </Box>
                                    </Fade>
                                </Card>
                            </Zoom>
                        </Grid>
                    })}
                </Grid>
            </SectionContainer>
        </Container>
    );
}


export default Services