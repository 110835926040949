import React, { useState, useRef } from "react"
import { Typography, Box } from "@mui/material";
import { Link } from "gatsby";

import Menu from 'material-ui-popup-state/HoverMenu'
import {
    usePopupState,
    bindHover,
    bindMenu,
} from 'material-ui-popup-state/hooks'
import HeaderSectionItem from "./headerSectionItem";

//MuiPopover-root
//MuiPaper-root MuiMenu-paper MuiPopover-paper MuiPaper-elevation8 MuiPaper-rounded
//MuiList-root MuiMenu-list MuiList-padding
//div


//MuiPaper-root MuiMenu-paper MuiPopover-paper MuiPaper-elevation8 MuiPaper-rounded
//MuiList-root MuiMenu-list MuiList-padding
//MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button

const HeaderSection = ({ navItem }) => {
    const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' })
    return (<>
        <Link style={{ color: "inherit", textDecoration: "none" }} to={navItem.pageLink}>
            <Typography {...bindHover(popupState)} color={process.env.GATSBY_COMPONENTS_APPBAR_TEXT_COLOR ?? "contrastText"} sx={{
                height: "50px",
                paddingLeft: "30px",
                fontSize: "18px",
                display: "flex",
                alignItems: "center",
                zIndex: 1000,
                cursor: "pointer",
                borderBottom: "2px solid rgba(0,0,0,0)",
                transition: "border-bottom .3s !important",
                letterSpacing: "1.25px",
                textTransform: "uppercase",
                "&:hover,&:focus": {
                    textDecoration: "underline",
                    textDecorationColor: theme => theme.palette.primary.light,
                    textDecorationThickness: "2px"
                },
            }} >
                {navItem.title}
            </Typography>
        </Link>
        {navItem.links.length > 0 ? (
            <Menu
                id="mouse-over-popover"
                {...bindMenu(popupState)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                getContentAnchorEl={null}
                disableRestoreFocus
                disableScrollLock
            >
                <Box sx={{
                    paddingLeft: "calc(50% - 320px)",
                    paddingRight: "calc(50% - 320px)",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                }}>
                    {navItem.links?.map(sectionItem => {
                        return <HeaderSectionItem label={sectionItem.label} link={sectionItem.link} subtitle={sectionItem.subtitle} />
                    })}
                </Box>
            </Menu>) : <></>}
    </>
    )
}

export default HeaderSection
