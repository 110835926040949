import React from "react"
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid,Box } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { greyColor } from "../../../theme/theme";
import StrapiRichText from "../../strapiRichText/index";


const FAQ = ({ sections }) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box sx={{ width: '100%'}}>
            {sections?.map((element, index) => {
                return <Accordion expanded={expanded === index} onChange={handleChange(index)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id={`panel1bh-header-${index}`}
                    >
                        <Typography sx={{
                             fontSize: theme =>theme.typography.pxToRem(15),
                             flexBasis: '100%',
                             flexShrink: 0,
                             color: theme =>theme.palette.text.secondary,
                             paddingLeft: "20px",
                        }}>{element.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        backgroundColor: greyColor,
                        paddingTop: "20px",
                        paddingBottom: "20px"
                    }}>
                        <Grid container md="12" lg="auto">
                            {element.columns?.map(col => {
                                return <Grid item>
                                    <Typography sx={{
                                        color: theme => theme.palette.text.secondary,
                                        paddingLeft: "20px",
                                    }}>
                                        <StrapiRichText>
                                            {col.text}
                                        </StrapiRichText>
                                    </Typography>

                                </Grid>
                            })}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            })}
        </Box>
    )
}

export default FAQ


