import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, Box } from "@mui/material"
// normalize css
import { } from "normalize.css/normalize.css"
import { styled } from "@mui/system";
// Page Components
import Header from "./header/header"
import Footer from "./footer"

import theme from "../theme/theme"
import ScrollTop from "./ScrollTop"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import Fab from "@mui/material/Fab"


const MainWrapper = styled('main')({
  position: "relative",
  minHeight: "80vh",
  overflow: "hidden",
});

const Layout = ({ children, langKey, navigation, footer, removeFooterMargin }) => {
  return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ position: "relative" }}>
          <Header navigation={navigation} />
          <MainWrapper>{children}</MainWrapper>
          <ScrollTop children={children}>
            <Fab color="secondary" size="small" aria-label="scroll back to top">
              <KeyboardArrowUpIcon />
            </Fab>
          </ScrollTop>
          <Footer footer={footer} removeFooterMargin={removeFooterMargin} />
        </Box>
      </ThemeProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
