import React from "react"

import { GatsbyImage } from "gatsby-plugin-image";
import { styled } from '@mui/system';

const StrapiImageWrapper = styled('div')({
    position: "relative",
    overflow: "hidden",
});

const StrapiImg = styled('img')({
    height: "100%",
    maxWidth: "none",
    margin: "0",
    width: "100%",
    objectFit: "cover"
});

const StrapiImage = ({ image, className, imageClassName, sxImage, sxImageWrapper }) => {
    if (!image) {
        return <></>
    }
    //Gatsby Image
    if (image && image.localFile) {
        return <GatsbyImage image={image.localFile.childImageSharp.gatsbyImageData} />
    }
    const { loading, url, srcset, alt } = image
    return <StrapiImageWrapper sx={sxImageWrapper}>
        <StrapiImg sx={sxImage} loading={loading} src={url} srcset={srcset} alt={alt}></StrapiImg>
    </StrapiImageWrapper>

}

export default StrapiImage
