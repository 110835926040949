import { useStaticQuery, graphql } from "gatsby"
import { createSlug } from "./slug";
import _ from "lodash";




export const MapNavigation = (navigation, locale) => {
  _.remove(navigation?.sections, { 'titel': "Immobilien" }) //TODO: Remove
  navigation?.sections.map(section => {
    section.title = section.titel
    if (section.link?.page?.titel) {
      section.pageLink = createSlug(section.link?.page?.titel, locale)
    }
    section.links.map(link => {
      link.subtitle = link.untertitel
      if (link.page?.titel)
        link.link = createSlug(link.page?.titel, locale)
    })
  })
}

export const MapZeitstrahl = (ereignisse, contentImages) => {
  ereignisse?.map(element => {
    element.description = element.text
    element.title = element.titel
    element.time = element.zeitpunkt
    if (contentImages) {
      contentImages[0].images?.map(img => {

        if (img?.localFile?.url === element?.bild?.url) {
          element.image = img
        }
      })
    }
  })
}

export const MapGallerie = (bilder, contentImages) => {
  bilder?.map(element => {
    if (contentImages) {
      contentImages[0].images?.map(img => {
        if (img?.localFile?.url === element?.bild?.url) {
          element.image = img
        }
      })
    }
  })
}

export const MapFooter = (footer_links, locale) => {
  footer_links?.map(link => {
    if (link?.page?.titel) {
      link.pageLink = createSlug(link?.page?.titel, locale)
    }
  })
}

export const MapFamilyBusinessLandingPage = (landingPage) => {
  landingPage.description = landingPage.beschreibung
  landingPage.backgroundImage = landingPage.hintergundbild
  landingPage.text_slider?.slide.map(slide => {
    slide.textColor = slide?.textfarbe?.farbe
    slide.backgroundColor = slide?.hintergrundfarbe?.farbe

  })
  landingPage.marketing.right = landingPage.marketing?.rechts
  landingPage.marketing.right.image = landingPage.marketing?.rechts?.bild

  landingPage.marketing.left = landingPage.marketing?.links
  landingPage.marketing.left.image = landingPage.marketing?.links?.bild

}

export const MapCraftsmanLandingPage = (landingPage) => {
  landingPage.services = landingPage.leistungen
  landingPage.contact = landingPage.kontakt

  landingPage.slider?.Slide.map(slide => {
    slide.title = slide?.titel
    slide.subtitle = slide?.untertitel
    slide.image = slide?.bild
  })

  landingPage.services.map(service => {
    service.title = service?.titel
    service.subtitle = service?.untertitel
    service.image = service?.bild
  })
}



export const MapAccordion = (sections) => {
  sections?.map(section => {
    section.title = section.titel
    section.columns = section.spalten
  })
}
