import React from "react";

import {  Typography, Button, Link as MuiLink, Grid, Paper, Card, CardActionArea, CardMedia, CardContent } from "@mui/material";
import SectionContainer from "../../layout/SectionContainer";

import Fade from "react-reveal/Fade";




const CTA = ({ text, buttontext, link }) => {
    return (
        <SectionContainer>
            <Card sx={{ backgroundColor: "#f7f7f7" }}>
                <CardContent sx={{ textAlign: "center", marginTop: "80px" }}>
                    <Fade>
                        <Typography variant="h2">
                            {text}
                        </Typography>
                    </Fade>
                </CardContent>
                <CardContent sx={{ textAlign: "center", marginTop: "30px" }}>
                    <MuiLink href={link}>
                        <Fade>
                            <Button size="large" variant="contained">{buttontext}</Button>
                        </Fade>
                    </MuiLink>
                </CardContent>
            </Card>
        </SectionContainer>
    );
}


export default CTA